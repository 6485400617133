<template>
  <div>
    <div
      v-if="
        !getViewMode &&
        (getUserType === 'medic' ||
          getUserType === 'superadmin' ||
          getUserType === 'nursing')
      "
      class="
        d-flex
        justify-content-end
        mb-3
        record__relative--sticky record__relative--sticky__bottom
      ">
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        v-if="!editable"
        @click="editable = true">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'edit']" />
        Editar
      </button>
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        v-if="!editable"
        @click="generatePDF">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'file-pdf']" />
        Descargar
      </button>
      <template v-else>
        <button
          class="btn btn-primary d-flex align-items-center m-3 px-3"
          @click="editable = false">
          <font-awesome-icon
            class="tableIcon me-4 text-white"
            :icon="['fa', 'times-circle']" />
          Cancelar
        </button>
        <button
          class="btn btn-primary d-flex align-items-center m-3 px-3"
          @click="saveData">
          <font-awesome-icon
            class="tableIcon me-4 text-white"
            :icon="['fa', 'save']" />
          Enviar
        </button>
      </template>
    </div>
    <RecordPdf :idPDF="idPatientPDF" />
    <ObstetricsGynecology ref="ObstetricsGynecology" :editable="editable" />
    <MenstrualHistory ref="MenstrualHistory" :editable="editable" />
    <RecordHistory ref="RecordHistory" :editable="editable" />
    <div
      v-if="
        !getViewMode &&
        (getUserType === 'medic' ||
          getUserType === 'superadmin' ||
          getUserType === 'nursing') &&
        editable
      "
      class="
        d-flex
        justify-content-end
        mb-3
        record__relative--sticky record__relative--sticky__bottom
      ">
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        @click="editable = false">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'times-circle']" />
        Cancelar
      </button>
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        @click="saveData">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'save']" />
        Enviar
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import { mapGetters } from "vuex";
import RecordPdf from "@/components/patient/details/record/RecordPdf.vue";
import ObstetricsGynecology from "./ObstetricsGynecology.vue";
import MenstrualHistory from "./MenstrualHistory.vue";
import RecordHistory from "./RecordHistory.vue";

export default {
  name: "RecordPatient",
  components: {
    ObstetricsGynecology,
    MenstrualHistory,
    RecordHistory,
    RecordPdf,
  },
  data() {
    return {
      editable: false,
      keyPatientPDF: 0,
      idPatientPDF: null,
    };
  },
  methods: {
    generatePDF() {
      this.keyPatientPDF += 1;
      this.idPatientPDF = this.keyPatientPDF;
    },
    saveData() {
      this.$refs.MenstrualHistory.menstrualData.menstrual_observations =
        this.$refs.MenstrualHistory.menstrualData.observations;
      delete this.$refs.MenstrualHistory.menstrualData.observations;
      const payload = {
        ...this.$refs.ObstetricsGynecology.gynecologyData,
        ...this.$refs.MenstrualHistory.menstrualData,
        ...this.$refs.RecordHistory.recordData,
      };
      this.$api.patient.putRecord(this.$route.params.id, payload).then(() => {
        this.editable = false;
      });
    },
  },
  computed: { ...mapGetters(["getUserType", "getViewMode"]) },
};
</script>
