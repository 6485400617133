<template>
  <div>
    <div class="card card-banner-patient mb-3 px-3">
      <div class="card-body">
        <div class="row">
          <div
            v-for="info in bannerStructureMain"
            :key="`${info.label} ${keyNum}`"
            :class="`${info.col} ${needAlignment(info.txtAlign)}`">
            <label :for="info.label" class="fw-bold">
              {{ info.label }}
            </label>
            <p
              class="d-block text-black-bold my-0"
              :title="patientData[info.key]">
              {{ patientData[info.key] ? patientData[info.key] : "-" }}
            </p>
          </div>
        </div>
        <div
          v-if="
            !getViewMode && getCompleteModeBanner && !getHidenSecondaryComponent
          "
          class="bannerIconEdit d-none d-lg-block">
          <font-awesome-icon
            class="mx-2 text-secondary tableIcon"
            title="Editar detalle paciente"
            @click="setCompleteModeBanner()"
            :icon="['fa', 'edit']" />
        </div>
      </div>
    </div>
    <div class="card card-banner-patient-partner px-3">
      <div class="card-body">
        <div class="row">
          <div
            v-for="info in bannerStructureSecondary"
            :key="`${info.label} ${keyNum}`"
            :class="`${info.col} ${needAlignment(info.txtAlign)}`">
            <template v-if="info.key === 'patient_tel'">
              <label :for="info.label" class="text-label-muted">
                {{ info.label }}
              </label>
              <p class="text-content-banner">
                {{
                  `${
                    patientData["patient_dial_code"]
                      ? `+${patientData["patient_dial_code"]}`
                      : ""
                  } ${patientData[info.key] ? patientData[info.key] : "-"}`
                }}
              </p>
            </template>
            <template v-else-if="info.key !== 'patient_age'">
              <label :for="info.label" class="text-label-muted">
                {{ info.label }}
              </label>
              <p class="text-content-banner">
                {{ patientData[info.key] ? patientData[info.key] : "-" }}
              </p>
            </template>
            <template v-else>
              <label :for="info.label" class="d-inline text-label-muted">
                {{ info.label }}
              </label>
              <p class="d-inline text-content-banner">
                {{ patientData[info.key] ? patientData[info.key] : "-" }}
              </p>
              <p class="text-content-banner">
                {{
                  patientData["patient_date_birth"]
                    ? patientData["patient_date_birth"]
                    : "-"
                }}
              </p>
            </template>
          </div>
        </div>
        <div class="row" v-if="patientData.has_partner">
          <div
            v-for="info in bannerStructureSecondary"
            :key="`${info.label} ${convertToPartner(info.key)} ${keyNum}`"
            :class="`${info.col} ${needAlignment(info.txtAlign)}`">
            <template v-if="info.key === 'patient_tel'">
              <label :for="info.label" class="text-label-muted">
                {{ info.label }}
              </label>
              <p class="text-content-banner">
                {{
                  `${
                    patientData["partner_dial_code"]
                      ? `+${patientData["partner_dial_code"]}`
                      : ""
                  } ${
                    patientData[convertToPartner(info.key)]
                      ? patientData[convertToPartner(info.key)]
                      : "-"
                  }`
                }}
              </p>
            </template>
            <template v-else-if="info.key !== 'patient_age'">
              <label :for="info.label" class="text-label-muted">
                {{ info.label }}
              </label>
              <p class="text-content-banner">
                {{
                  patientData[convertToPartner(info.key)]
                    ? patientData[convertToPartner(info.key)]
                    : "-"
                }}
              </p>
            </template>
            <template v-else>
              <label :for="info.label" class="d-inline text-label-muted">
                {{ info.label }}
              </label>
              <p class="d-inline text-content-banner">
                {{
                  patientData[convertToPartner(info.key)]
                    ? patientData[convertToPartner(info.key)]
                    : "-"
                }}
              </p>
              <p class="text-content-banner">
                {{
                  patientData["partner_date_birth"]
                    ? patientData["partner_date_birth"]
                    : "-"
                }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "PatientBanner",
  data() {
    return {
      bannerStructureMain: {
        nec: {
          label: "NEC:",
          key: "nec",
          col: "col-6 col-sm-6 col-md-4 col-lg-1 col-xl-1 mb-2",
          txtAlign: "text-start",
        },
        first_appointment: {
          label: "PV:",
          key: "first_appointment",
          col: "col-6 col-sm-6 col-md-4 col-lg-2 col-xl-2 mb-2",
          txtAlign: "text-start",
        },
        cacit_name: {
          label: "Cacit:",
          key: "cacit_name",
          col: "col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 mb-2",
          txtAlign: "text-start",
        },
        medic_name: {
          label: "Médico:",
          key: "medic_name",
          col: "col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2",
          txtAlign: "text-start",
        },
        abc_user: {
          label: "ABC:",
          key: "abc_user",
          col: "col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-2",
          txtAlign: "text-start",
        },
      },
      bannerStructureSecondary: {
        patient_name: {
          label: "Nombre:",
          key: "patient_name",
          col: "col-8 col-sm-8 col-md-6 col-lg-6 col-xl-3 mb-1",
          txtAlign: "text-start",
        },
        patient_age: {
          label: "Edad:",
          key: "patient_age",
          col: "col-4 col-sm-4 col-md-6 col-lg-6 col-xl-3 mb-1",
          txtAlign: "text-start",
        },
        patient_tel: {
          label: "Tel:",
          key: "patient_tel",
          col: "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-1",
          txtAlign: "text-start",
        },
        patient_email: {
          label: "Email:",
          key: "patient_email",
          col: "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3 mb-1",
          txtAlign: "text-start",
        },
      },
      keyNum: 0,
      inputsInfoHeader: [],
      inputsInfoPatient: [],
      inputsInfoCouple: [],
      noTextAlign: false,
      patientData: {
        nec: null,
        first_appointment: null,
        cacit_name: null,
        medic_name: null,
        patient_name: null,
        patient_date_birth: null,
        contact_medium: null,
        patient_age: null,
        patient_tel: null,
        patient_email: null,
        partner_name: null,
        partner_date_birth: null,
        partner_age: null,
        partner_tel: null,
        partner_email: null,
        has_partner: false,
        patient_type: null,
        abc_user: null,
      },
    };
  },
  mounted() {
    window.addEventListener("resize", this.sizeWindow);
    this.sizeWindow();
    this.getPatient();
  },
  methods: {
    ...mapMutations(["setHasPartner", "setCompleteModeBanner"]),
    getPatient() {
      this.$api.patient.getPatient(this.$route.params.id).then((res) => {
        const { data } = res;
        this.patientData = data;
        this.setHasPartner(res.data.has_partner);
      });
    },
    needAlignment(txtAlign) {
      if (this.noTextAlign) {
        return "";
      }
      return txtAlign;
    },
    sizeWindow() {
      if (window.innerWidth < 1440) {
        this.noTextAlign = true;
      } else {
        this.noTextAlign = false;
      }
      this.keyNum += 1;
    },
    convertToPartner(key) {
      return key.replace("patient", "partner");
    },
  },
  computed: {
    ...mapGetters([
      "getViewMode",
      "getCompleteModeBanner",
      "getHidenSecondaryComponent",
    ]),
  },
};
</script>

<style></style>
