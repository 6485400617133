<template>
  <!-- styles mods are inside _abc.scss -->
  <b-modal
    ref="followUpPendingModal"
    id="followUpPendingModal"
    content-class="p-4"
    size="md"
    hide-header
    hide-footer
    @hide="cancelModal">
    <div class="followUpModal">
      <h3 class="text-secondary mb-3">Detalles de seguimiento</h3>
      <form class="followUpModal__form">
        <div class="form-group mb-2">
          <label for="title" class="labelColor form-label mb-0"> Titulo </label>
          <p class="labelValueColor" v-text="followUp.title"></p>
        </div>
        <div class="d-flex">
          <div class="col-6 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Fecha de creación
            </label>
            <p class="labelValueColor" v-text="followUp.created_at"></p>
          </div>
          <div class="col-6 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Fecha programada
            </label>
            <p class="labelValueColor" v-text="followUp.date_time"></p>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-12 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Medio de seguimiento
            </label>
            <p
              class="labelValueColor"
              v-text="followUpTypeTranslate[followUp.type_follow_up_task]"></p>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-12 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Comentario ABC
            </label>
            <p
              class="labelValueColor"
              v-text="
                followUp.comment ? followUp.comment : 'Sin comentario'
              "></p>
          </div>
        </div>
        <div class="d-flex pt-2">
          <div class="col-12">
            <button
              class="btn btn-secondary me-1 w-100"
              @click="cancelModal()"
              v-text="'Aceptar'"
              type="button" />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Form from "@/helpers/form";

export default {
  name: "FollowUpPending",
  props: {
    folloUpID: {
      type: Number,
      required: true,
    },
    showFollowUpFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    showFollowUpFlag(newValue) {
      if (newValue) {
        this.$api.patient.showFollowUp(this.folloUpID).then((res) => {
          this.followUp.update(res.data);
        });
      }
    },
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "followUpPendingModal",
      ref: this.$refs.followUpPendingModal,
    });
  },
  data() {
    return {
      followUp: new Form({
        note: "",
        answered: false,
        color: "",
        comment: "",
        created_at: null,
        date_time: null,
        title: "",
        type_follow_up_task: "",
        updated_at: null,
      }),
      followUpTypeTranslate: {
        call: "Llamada",
        whatsapp: "Whatsapp",
        virtual: "Virtual",
      },
    };
  },
  methods: {
    cancelModal() {
      this.$parent.showFollowUpFlag = false;
      this.followUp.reset();
      this.$store.getters.getModal("followUpPendingModal").hide();
    },
  },
};
</script>
