<template>
  <div class="card card-citmer cardRadius mb-4 mt-0 pt-0 internalBackground">
    <b-table
      :items="files"
      :fields="fieldsFiles"
      :table-class="'patientsHeadClass'"
      emptyText="No hay registros"
      show-empty
      striped
      borderless>
      <template v-slot:cell(file_id)="row">
        <button
          v-b-modal.medicNoteABC
          @click="getFile(row.item.file_id)"
          v-b-modal.fileModal
          class="btn text-secondary"
          v-text="'Ver imagen'"></button>
      </template>
    </b-table>
    <b-modal
      ref="fileModal"
      id="fileModal"
      size="xl"
      centered
      hide-header
      hide-footer>
      <iframe
        :src="file"
        style="width: 100%; height: 700px"
        frameborder="0"></iframe>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "FilesComponent",
  data() {
    return {
      files: [],
      file: null,
      fieldsFiles: [
        {
          class: "text-center ",
          key: "date",
          label: "Fecha",
        },
        { class: "text-center ", key: "name", label: "Nombre" },
        { class: "text-center ", key: "file_id", label: "Archivo" },
      ],
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      this.$api.patient
        .getFiles({ patient_id: this.$route.params.id })
        .then((res) => {
          this.files = res.data.files;
        });
    },
    getFile(id) {
      this.$api.patient.getFile(id).then((res) => {
        this.file = res.data.url;
      });
    },
  },
};
</script>
