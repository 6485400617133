<template>
  <div
    class="patientView"
    :class="
      getViewMode || getCompleteModeBanner ? 'patientView__complete' : ''
    ">
    <div class="patientView__main">
      <div class="patientView__main--content">
        <PatientBanner ref="PatientBanner" class="mb-4" />
        <div class="folderContainer mb-3">
          <div class="arrow" @click="moveFolders('left')">
            <div>
              <font-awesome-icon :icon="['fas', 'chevron-left']" />
            </div>
          </div>
          <div class="content">
            <FolderComponent />
          </div>
          <div class="arrow" @click="moveFolders('right')">
            <div>
              <font-awesome-icon :icon="['fas', 'chevron-right']" />
            </div>
          </div>
        </div>
        <keep-alive>
          <component ref="componentMain" :is="componentMain" />
        </keep-alive>
      </div>
    </div>
    <div v-if="!getViewMode" class="patientView__secondary">
      <component
        :key="keySecondary"
        ref="componentSecondary"
        :is="componentSecondary" />
    </div>
    <!-- <PatientModal /> -->
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/extensions */

import { mapGetters, mapActions } from "vuex";
import detailComponents from "@/constants/patient/detailComponents.js";
import { folderByTypeUsers } from "@/constants/permissions";

export default {
  name: "Detail",
  components: detailComponents,
  data() {
    return {
      keySecondary: 1,
      folderByTypeUsers,
    };
  },
  created() {
    const userType = this.getUserType;
    const folder = this.folderByTypeUsers.filter(
      (folderOption) => folderOption.userType === userType
    );
    this.folderOption = folder[0].value;
  },
  methods: {
    ...mapActions(["updateFolderOption", "updateFolderComponents"]),
    getFiles() {
      this.$refs.componentMain.getFiles();
    },
    moveFolders(direction) {
      const content = document.querySelector(".folders");
      content.scrollLeft += direction === "left" ? -50 : 50;
    },
  },
  computed: {
    ...mapGetters([
      "getFolderOption",
      "getFolderComponents",
      "getViewMode",
      "getCompleteModeBanner",
      "getUserType",
    ]),
    folderOption: {
      get() {
        return this.getFolderOption;
      },
      set(newValues) {
        this.updateFolderOption(newValues[0]);
        this.updateFolderComponents(newValues[1]);
      },
    },
    FolderComponents() {
      return this.getFolderComponents;
    },
    componentMain() {
      return this.FolderComponents[0] || null;
    },
    componentSecondary() {
      return this.FolderComponents[1];
    },
  },
};
</script>
