<template>
  <div
    class="card py-3 card-citmer cardRadius mb-3"
    v-if="
      (getUserType !== 'abc' &&
      getUserType !== 'manager_abc' &&
      this.patient_historical_last
        ? this.patient_historical_last.appointment_id != null
        : false) && !getCompleteModeBanner
    ">
    <HideIcon />
    <!-- HEADER CARD -->
    <div class="d-flex px-4 align-items-center">
      <div class="titles">
        <span class="titles__clear">Servicio: </span>
        <span
          v-text="
            this.patient_historical_last
              ? this.patient_historical_last.service
              : ''
          "></span>
      </div>
    </div>
    <hr />
    <!-- HEADER CARD END -->

    <!-- CONTENT CARD -->
    <div class="px-4">
      <div class="mb-4">
        <div class="mb-0 titles">Notas Médicas</div>
      </div>
      <div class="mb-4">
        <b-form-textarea
          :state="!$v.note.$error"
          v-model="note"
          class="form-control cardRadius inputsGeneralClass w-100"
          rows="6"></b-form-textarea>
      </div>
      <template v-if="includesIdOrPrehistorical">
        <div class="d-flex align-items-center">
          <div class="col-11">
            <model-list-select
              class="my-3 cardRadius fw-bold bgGrey"
              :list="diagnostics"
              v-model="objectDiagnostic"
              option-value="id"
              option-text="name"
              placeholder="Diagnóstico">
            </model-list-select>
          </div>
          <div class="col-1 text-end">
            <span
              @click="pushDiagnostic"
              class="pb-1 text-primary pointer"
              :disabled="!diagnostics">
              <font-awesome-icon
                class="svg-inline--fa fa-search fa-w-16"
                :icon="['fas', 'plus']" />
            </span>
          </div>
        </div>
        <div
          v-for="(diagnostic, index) in itemsDiagnostics"
          :key="index"
          class="d-flex align-items-center mb-2">
          <div class="card cardRadius col-11 p-2 bgGrey">
            <span class="fw-bold" v-text="diagnostic.name"></span>
          </div>
          <div class="col-1 text-end">
            <span @click="popDiagnostic(diagnostic)" class="pointer">
              <font-awesome-icon
                class="svg-inline--fa fa-search fa-w-16"
                style="color: #959594"
                :icon="['fas', 'minus']" />
            </span>
          </div>
        </div>
      </template>
    </div>
    <template v-if="includesIdOrPrehistorical">
      <hr />
      <div class="p-4">
        <div class="row align-items-center">
          <div class="col-sm-3 col-lg-12 col-xl-3">
            <div class="mb-0 titles">Plan</div>
          </div>
          <div class="col-sm-9 col-lg-12 col-xl-9">
            <model-list-select
              class="cardRadius fw-bold bgGrey"
              :list="plans"
              v-model="objectPlan"
              option-value="id"
              option-text="name"
              placeholder="Planes">
            </model-list-select>
          </div>
        </div>
      </div>
    </template>
    <hr />
    <div class="px-4">
      <div class="mb-4">
        <div class="mb-0 titles">Medicamentos</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="col-11">
          <model-list-select
            class="my-3 cardRadius fw-bold bgGrey"
            :list="medicines"
            v-model="objectMedicines"
            option-value="id"
            option-text="description"
            placeholder="Medicamentos">
          </model-list-select>
        </div>
        <div class="col-1 text-end">
          <span
            @click="pushMedicines"
            class="pb-1 text-primary pointer"
            :disabled="!medicines">
            <font-awesome-icon
              class="svg-inline--fa fa-search fa-w-16"
              :icon="['fas', 'plus']" />
          </span>
        </div>
      </div>
      <div
        :key="index"
        v-for="(medicine, index) in itemsMedicines"
        class="d-flex align-items-center mb-2">
        <div class="card cardRadius col-11 p-2 bgGrey">
          <span class="fw-bold" v-text="medicine.description"></span>
        </div>
        <div class="col-1 text-end">
          <span @click="popMedicines(medicine)" class="text-primary pointer">
            <font-awesome-icon
              class="svg-inline--fa fa-search fa-w-16"
              :icon="['fas', 'minus']" />
          </span>
        </div>
      </div>
      <button
        @click="postHistorical"
        class="btn btn-primary py-2 col-12 radiusButton mt-4">
        Guardar
      </button>
    </div>
    <!-- CONTENT CARD END -->
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable no-useless-concat */

import { ModelListSelect } from "vue-search-select";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import HideIcon from "@/components/patient/HideIcon.vue";

export default {
  name: "CreateMedicNote",
  components: {
    ModelListSelect,
    HideIcon,
  },
  props: {
    medicNoteData: {
      type: Object,
      required: false,
    },
  },
  validations() {
    return {
      note: {
        required,
      },
      payloadDiagnostics: {
        required: requiredIf(function diagnostic() {
          return this.includesIdOrPrehistorical;
        }),
      },
      objectPlan: {
        required: requiredIf(function plan() {
          return this.includesIdOrPrehistorical;
        }),
      },
    };
  },
  data() {
    return {
      note: "",
      patient_historical_last: null,
      itemsDiagnostics: [],
      payloadDiagnostics: [],
      itemsMedicines: [],
      payloadMedicines: [],
      diagnostics: [],
      plans: [],
      medicines: [],
      objectDiagnostic: {},
      objectMedicines: {},
      objectPlan: {},
    };
  },
  mounted() {
    const fullMode =
      this.getUserType !== "abc" && this.patient_historical_last
        ? this.patient_historical_last.appointment_id != null
        : false;
    if (!fullMode) {
      this.updateBannerMode(true);
      this.updateHidenSecondaryComponent(true);
    } else {
      this.updateBannerMode(false);
      this.updateHidenSecondaryComponent(false);
    }
  },
  created() {
    if (this.getUserType !== "nursing") {
      this.getHistoricalLast();
      this.$api.patient.getDiagnostics().then((res) => {
        this.diagnostics = res.data.diagnostics;
        this.diagnostics.push({
          cost: 0,
          id: "No aplica",
          name: "No aplica",
        });
      });
      this.$api.patient.getPlans().then((res) => {
        this.plans = res.data.plans;
        this.plans.push({
          cost: 0,
          id: "Pendiente",
          name: "Pendiente",
        });
      });
      this.$api.patient.getMedicines().then((res) => {
        this.medicines = res.data.medicines;
      });
      this.$v.$reset();
    }
  },
  methods: {
    ...mapActions([
      "updateAlerts",
      "updateBannerMode",
      "updateHidenSecondaryComponent",
    ]),
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
    getHistoricalLast() {
      const payload = {
        patient_id: this.$route.params.id,
      };
      this.$api.patient.getHistoricalLast(payload).then((res) => {
        this.patient_historical_last = res.data;
        const fullMode =
          this.getUserType !== "abc" && this.patient_historical_last
            ? this.patient_historical_last.appointment_id != null
            : false;
        if (!fullMode) {
          this.updateBannerMode(true);
          this.updateHidenSecondaryComponent(true);
        } else {
          this.updateBannerMode(false);
          this.updateHidenSecondaryComponent(false);
        }
      });
    },
    pushDiagnostic() {
      if (
        Object.keys(this.objectDiagnostic).length !== 0 &&
        !this.itemsDiagnostics.includes(this.objectDiagnostic) &&
        !this.payloadDiagnostics.includes(this.objectDiagnostic)
      ) {
        this.itemsDiagnostics.push(this.objectDiagnostic);
        this.payloadDiagnostics.push({ id: this.objectDiagnostic.id });
        this.objectDiagnostic = {};
      }
    },
    popDiagnostic(item) {
      this.itemsDiagnostics = this.itemsDiagnostics.filter(
        (it) => it.id !== item.id
      );
      this.payloadDiagnostics = this.payloadDiagnostics.filter(
        (it) => it.id !== item.id
      );
    },
    pushMedicines() {
      if (
        Object.keys(this.objectMedicines).length !== 0 &&
        !this.itemsMedicines.includes(this.objectMedicines) &&
        !this.payloadMedicines.includes(this.objectMedicines)
      ) {
        this.itemsMedicines.push(this.objectMedicines);
        this.payloadMedicines.push({ id: this.objectMedicines.id });
        this.objectMedicines = {};
      }
    },
    popMedicines(item) {
      this.itemsMedicines = this.itemsMedicines.filter(
        (it) => it.id !== item.id
      );
      this.payloadMedicines = this.payloadMedicines.filter(
        (it) => it.id !== item.id
      );
    },
    validateDiagnostic() {
      const diagnosticValidation = !!this.payloadDiagnostics.find(
        (item) => item.id === "No aplica"
      );
      return diagnosticValidation;
    },
    postHistorical() {
      if (this.isFormDataValid()) {
        const payload = {
          appointment_id: this.$route.params.appointment_id,
          note: this.note,
          plan_id:
            this.objectPlan.id === "Pendiente" ? null : this.objectPlan.id,
          diagnostics: this.validateDiagnostic() ? [] : this.payloadDiagnostics,
          medicines: this.payloadMedicines,
        };
        this.$api.patient.postHistorical(payload).then(() => {
          this.getHistoricalLast();
          this.$parent.$refs.componentMain.getCompleteHistoryDetail();
          this.$parent.$refs.PatientBanner.getPatient();
          this.$parent.keySecondary += 1;
        });
      } else {
        this.updateAlerts({
          status: "warning",
          content: "Debe llenar los campos Nota, Diagnóstico y Plan.",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["getUserType", "getCompleteModeBanner"]),
    includesIdOrPrehistorical() {
      return (
        [1, 3, 4, 5, 6, 7, 8, 10, 390].includes(
          this.patient_historical_last.service_id
        ) || !this.patient_historical_last.pre_historical
      );
    },
  },
};
</script>
