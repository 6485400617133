<template>
  <div class="card py-3 card-citmer cardRadius mb-3">
    <!-- HEADER CARD -->
    <div class="d-flex align-items-center">
      <div class="col-11 text-center ps-5 ms-2 mb-0 titles">
        Gineco-Obstetricos
      </div>
    </div>
    <hr />
    <!-- HEADER CARD END -->

    <!-- CARD CONTENT -->
    <div class="p-5">
      <!-- HEADER TABLE -->
      <div class="row mb-3">
        <div class="col-3"></div>
        <div class="d-flex text-primary fw-bold col-9">
          <span class="col-3 text-center">Partos</span>
          <span class="col-3 text-center">Cesareas</span>
          <span class="col-3 text-center">Abortos</span>
          <span class="col-3 text-center">Ectópicos</span>
        </div>
      </div>
      <!-- HEADER TABLE END -->

      <!-- TABLE CONTENT -->
      <div class="row mb-5">
        <div class="d-flex flex-column col-3">
          <span class="mb-3 mt-2">A Termino: </span>
          <span>A Pre-Termino: </span>
        </div>
        <div class="d-flex flex-column col-9">
          <div class="row mb-3">
            <div class="col-3 text-center">
              <input
                v-model="gynecologyData.term_childbirth"
                class="form-control inputsGeneralClass w-100 text-center"
                type="number"
                :disabled="!editable" />
            </div>
            <div class="col-3 text-center">
              <input
                v-model="gynecologyData.term_caesarean"
                class="form-control inputsGeneralClass w-100 text-center"
                type="number"
                :disabled="!editable" />
            </div>
            <div class="col-3 text-center">
              <input
                v-model="gynecologyData.term_abortion"
                class="form-control inputsGeneralClass w-100 text-center"
                type="number"
                :disabled="!editable" />
            </div>
            <div class="col-3 text-center">
              <input
                v-model="gynecologyData.term_ectopic"
                class="form-control inputsGeneralClass w-100 text-center"
                type="number"
                :disabled="!editable" />
            </div>
          </div>
          <div class="row">
            <div class="col-3 text-center">
              <input
                v-model="gynecologyData.pre_childbirth"
                class="form-control inputsGeneralClass w-100 text-center"
                type="number"
                :disabled="!editable" />
            </div>
            <div class="col-3 text-center">
              <input
                v-model="gynecologyData.pre_caesarean"
                class="form-control inputsGeneralClass w-100 text-center"
                type="number"
                :disabled="!editable" />
            </div>
          </div>
        </div>
      </div>
      <!-- TABLE CONTENT END -->

      <!-- FORM -->
      <div class="d-flex mb-3">
        <div class="col-xl-5 col-md-7">
          <label>¿Gestaciones de la misma pareja?: </label>
        </div>
        <div class="row col-3">
          <div class="form-check col-6">
            <label class="form-check-label" for="flexRadioDefault1">Yes</label>
            <input
              class="form-check-input"
              type="radio"
              v-model="gynecologyData.same_partner"
              :value="true"
              :disabled="!editable"
              id="flexRadioDefault1" />
          </div>
          <div class="form-check col-6">
            <label class="form-check-label" for="flexRadioDefault2">No</label>
            <input
              class="form-check-input"
              type="radio"
              v-model="gynecologyData.same_partner"
              :value="false"
              :disabled="!editable"
              id="flexRadioDefault2" />
          </div>
        </div>
      </div>
      <div>
        <div>
          <textarea
            v-model="gynecologyData.observations"
            class="form-control inputsGeneralClass w-100"
            rows="5"
            placeholder="Observaciones"
            :disabled="!editable"></textarea>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <!-- CARD CONTENT END -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "ObstetricsGynecology",
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      gynecologyData: {
        term_childbirth: 0,
        term_caesarean: 0,
        term_abortion: 0,
        term_ectopic: 0,
        pre_childbirth: 0,
        pre_caesarean: 0,
        same_partner: true,
        observations: 0,
      },
    };
  },
  mounted() {
    this.$api.patient.getRecordGynecology(this.$route.params.id).then((res) => {
      this.gynecologyData = res.data;
    });
  },
  methods: {
    putRecordGynecology() {
      this.$api.patient
        .putRecordGynecology(this.$route.params.id, this.gynecologyData)
        .then(() => {
          this.editable = false;
        });
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
};
</script>
.
