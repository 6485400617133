<template>
  <!-- styles mods are inside _abc.scss -->
  <b-modal
    ref="followUpTaskModal"
    id="followUpTaskModal"
    content-class="p-4"
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer>
    <div class="followUpModal">
      <h3 class="mb-3">Tarea de Seguimiento</h3>
      <form @submit.stop.prevent="onSubmit" class="followUpModal__form">
        <div class="form-group mb-2">
          <label for="title" class="form-label mb-0"
            ><span class="blueCitmer">*</span>Título</label
          >
          <input
            class="form-control inputsGeneralClassSecond"
            :class="{
              'is-invalid':
                $v.followUp.title.$error || $v.followUp.title.$invalid,
            }"
            v-model="followUp.title"
            type="text" />
        </div>
        <div class="form-group mb-2">
          <label for="type_follow_up_task">
            <span class="blueCitmer">*</span>Tipo de tarea de seguimiento
          </label>
          <select
            v-model="followUp.type_follow_up_task"
            :class="{
              'is-invalid':
                $v.followUp.type_follow_up_task.$error ||
                $v.followUp.type_follow_up_task.$invalid,
            }"
            class="form-control inputsGeneralClassSecond">
            <option value="call">Llamada</option>
            <option value="whatsapp">Whatsapp</option>
            <option value="virtual">Virtual</option>
          </select>
        </div>
        <div
          class="form-group mb-2"
          :class="{
            errorDate:
              $v.followUp.date_time.$error || $v.followUp.date_time.$invalid,
          }">
          <label for="date_time"
            ><span class="blueCitmer">*</span>Fecha y hora programada</label
          >
          <date-picker
            class="d-block w-100"
            input-class="form-control inputsGeneralClassSecond"
            format="DD-MM-YYYY HH:mm"
            :disabled-date="notBeforeToday"
            type="datetime"
            v-model="followUp.date_time" />
        </div>
        <div class="form-group mb-3">
          <label for="comment">Comentario</label>
          <textarea
            v-model="followUp.comment"
            cols="25"
            rows="5"
            class="form-control inputsGeneralClassSecond"></textarea>
        </div>
        <div class="row justify-content-end px-2">
          <button
            class="btn btn-primary ms-1 col-6"
            v-text="'Guardar'"
            type="submit" />
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Form from "@/helpers/form";

export default {
  name: "FollowUpTasks",
  components: {
    DatePicker,
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "followUpTaskModal",
      ref: this.$refs.followUpTaskModal,
    });
  },
  data() {
    return {
      followUp: new Form({
        title: "",
        type_follow_up_task: "call",
        date_time: new Date(),
        comment: "",
      }),
    };
  },
  validations: {
    followUp: {
      title: {
        required,
      },
      type_follow_up_task: {
        required,
      },
      date_time: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["getClinic"]),
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error && !this.$v.$invalid;
    },
    cancelModal() {
      this.followUp.reset();
      this.$store.getters.getModal("followUpTaskModal").hide();
    },
    onSubmit() {
      if (!this.isFormDataValid()) return;
      const payload = this.followUp.data();
      payload.patient_id = this.$route.params.id;
      payload.clinic_id = this.getClinic;
      this.$api.patient.postFollowUp(payload).then((res) => {
        this.cancelModal();
        this.$parent.saveData(true, res.data.follow_up_task_id);
      });
    },
  },
};
</script>
