<template>
  <div
    class="card py-3 card__shadow cardRadius mb-3"
    v-if="validNutritionCard && !getCompleteModeBanner">
    <HideIcon />
    <div class="d-flex px-4 align-items-center">
      <div class="mb-0 titles">Notas</div>
    </div>
    <hr />
    <div class="px-4 mb-5">
      <b-form-textarea
        class="form-control cardRadius inputsGeneralClass w-100"
        :state="!$v.notes.$error"
        v-model="notes"
        placeholder="Notas..."
        style="resize: none"
        rows="6"></b-form-textarea>
    </div>
    <div class="d-flex px-4 align-items-center">
      <div class="col-12">
        <div>
          <h4 class="titles text-primary mb-0 fw-bold me-4">
            Indicadores Nutricionales
          </h4>
        </div>
      </div>
    </div>
    <hr />
    <div class="row px-4 justify-content-between text-center">
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.height.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="height"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>Estatura</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.weight.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="weight"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>Peso</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.imc.$error"
            type="number"
            @keypress="isNumber($event)"
            v-model="imc"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>IMC</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.grease.$error"
            type="number"
            @keypress="isNumber($event)"
            v-model="grease"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>% Grasa</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.muscle.$error"
            type="number"
            @keypress="isNumber($event)"
            v-model="muscle"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>% Musculo</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.visceral_fat.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="visceral_fat"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>Grasa Viseral</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.metabolic_age.$error"
            type="number"
            @keypress="isNumber($event)"
            v-model="metabolic_age"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>Edad Metabolica</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.hip.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="hip"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>C. Cadera</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.waist.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="waist"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>C. Cintura</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.abdominal.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="abdominal"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>C. Abdominal</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.blood_pressure.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="blood_pressure"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>Presion Arterial</div>
      </div>
      <div class="d-flex flex-column col-4 mb-3">
        <div>
          <b-form-input
            :state="!$v.heart_rate.$error"
            type="number"
            @keypress="isNumberOrDot($event)"
            v-model="heart_rate"
            class="form-control inputsGeneralClass w-100" />
        </div>
        <div>Frecuencia Cardiaca</div>
      </div>
    </div>
    <div class="px-4 text-end">
      <button class="btn" @click="saveData" :disabled="!isFormDataValid">
        <font-awesome-icon
          class="psichoCardIcon text-primary"
          :icon="['fas', 'save']" />
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import HideIcon from "@/components/patient/HideIcon.vue";

export default {
  name: "NutritionCard",
  components: {
    HideIcon,
  },
  data() {
    return {
      notes: "",
      height: "",
      weight: "",
      imc: "",
      grease: "",
      muscle: "",
      visceral_fat: "",
      metabolic_age: "",
      hip: "",
      waist: "",
      abdominal: "",
      blood_pressure: "",
      heart_rate: "",
      psyNutriKey: 1,
      currentAppointment: false,
      user_types: [],
      patient_nutrition_last: null,
    };
  },
  validations: {
    notes: {
      required,
    },
    height: {
      required,
    },
    weight: {
      required,
    },
    imc: {
      required,
    },
    grease: {
      required,
    },
    muscle: {
      required,
    },
    visceral_fat: {
      required,
    },
    metabolic_age: {
      required,
    },
    hip: {
      required,
    },
    waist: {
      required,
    },
    abdominal: {
      required,
    },
    blood_pressure: {
      required,
    },
    heart_rate: {
      required,
    },
  },
  mounted() {
    this.$v.$reset();
    this.getCurrentAppointment();
    this.getPatientNutritionLast();
    if (!this.validNutritionCard) {
      this.updateBannerMode(true);
      this.updateHidenSecondaryComponent(true);
    } else {
      this.updateBannerMode(false);
      this.updateHidenSecondaryComponent(false);
    }
  },
  methods: {
    ...mapActions(["updateBannerMode", "updateHidenSecondaryComponent"]),
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode !== 8 && charCode !== 0 && charCode < 48) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
    isNumberOrDot(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode !== 8 &&
          charCode !== 0 &&
          charCode < 48 &&
          charCode !== 46) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
    getCurrentAppointment() {
      this.$api.appointments
        .currentAppointment({ patient_id: this.$route.params.id })
        .then((res) => {
          this.currentAppointment = res.data.current_appointment;
          this.user_types = res.data.user_types;
        });
    },
    getPatientNutritionLast() {
      const payload = {
        patient_id: this.$route.params.id,
      };
      this.$api.patient.getPatientNutritionLast(payload).then((res) => {
        this.patient_nutrition_last = res.data;
      });
    },
    saveData() {
      const payload = {
        patient_id: this.$route.params.id,
        note: this.notes,
        height: this.height,
        weight: this.weight,
        bmi: this.imc,
        fat: this.grease,
        muscle: this.muscle,
        visceral_fat: this.visceral_fat,
        metabolic_age: this.metabolic_age,
        hip: this.hip,
        waist: this.waist,
        abdominal: this.abdominal,
        blood_pressure: this.blood_pressure,
        heart_rate: this.heart_rate,
        appointment_id: this.$route.params.appointment_id,
      };
      this.$api.patient.postPatientNutrition(payload).then(() => {
        this.$parent.$refs.componentMain.getPatientNutritions();
        this.$parent.keySecondary += 1;
      });
    },
  },
  computed: {
    ...mapGetters(["getUserType", "getCompleteModeBanner"]),
    validNutritionCard() {
      return (
        this.getUserType === "nutrition" ||
        (this.currentAppointment &&
        this.user_types.includes(this.getUserType) &&
        this.patient_nutrition_last
          ? this.patient_nutrition_last.appointment_id != null
          : false)
      );
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
  },
};
</script>
