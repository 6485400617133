<template>
  <div class="folders">
    <button
      v-for="(folder, index) in folders"
      :key="index"
      @click="updateFolders(folder)"
      class="folders__options"
      :class="{
        'folders__options--inactive':
          folderPermission(folder) === 'no_permission',
        'folders__options--active': folderOption === folder.option,
      }">
      <div
        class="folders__options--btn"
        :class="
          smallerIcons.includes(folder.icon) ? 'folders__options--btn14' : ''
        ">
        <div>
          <font-awesome-icon
            :class="[notifications.includes(folder.option) ? 'mt-3' : '']"
            :icon="[folder.iconExt, folder.icon]" />
          <div
            v-if="notifications.includes(folder.option)"
            class="notification"></div>
        </div>
      </div>
      <span class="folders__options--txt" v-text="folder.name"></span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { folders } from "@/constants/patient";

export default {
  name: "FolderComponent",
  data() {
    return {
      folders,
      notifications: [],
      smallerIcons: ["microscope", "folder-open", "weight", "file-signature"],
    };
  },
  methods: {
    ...mapActions(["updateFolderOption", "updateFolderComponents"]),
    ...mapGetters(["getFolderOption"]),
    updateFolders(folder) {
      const optionPrimary =
        this.folderPermission(folder) !== "no_permission"
          ? folder.option
          : "no_permission";
      if (optionPrimary === "no_permission") return;
      const optionSecondary =
        this.folderPermissionSecondary(folder) !== "no_permission"
          ? folder.components[1]
          : null;
      this.folderOption = [optionPrimary, optionSecondary];
    },
    folderPermission(folder) {
      const hasPermissionToFolder = folder.permissionByUserType.includes(
        this.getUserType
      );
      const validationFolder = this.folderOption === folder.option;
      if (folder.isCreated && hasPermissionToFolder) {
        return validationFolder ? "active" : "inactive";
      }
      return "no_permission";
    },
    folderPermissionSecondary(folder) {
      const validationType = folder.permissionByUserTypeSecondary.includes(
        this.getUserType
      );
      const validationFolder = this.folderOption === folder.option;
      if (folder.isCreated && validationType) {
        return validationFolder ? "active" : "inactive";
      }
      return "no_permission";
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
    folderOption: {
      get() {
        return this.getFolderOption();
      },
      set(newValues) {
        this.updateFolderOption(newValues[0]);
        this.updateFolderComponents(newValues);
      },
    },
  },
};
</script>
