import PsychologyCard from "@/components/patient/details/psychology/PsychologyCard.vue";
import Historical from "@/components/patient/details/historical/Historical.vue";
import ABC from "@/components/patient/details/abc/ABC.vue";
import ABCNotes from "@/components/patient/details/abc/ABCNotes.vue";
import Files from "@/components/patient/details/files/Files.vue";
import FilesCard from "@/components/patient/details/files/FilesCard.vue";
import Record from "@/components/patient/details/record/Record.vue";
import Laboratories from "@/components/patient/details/laboratories/Laboratories.vue";
import CreateMedicNote from "@/components/patient/details/shared/CreateMedicNote.vue";
import FolderComponent from "@/components/patient/details/shared/FolderComponent.vue";
import PatientBanner from "@/components/patient/details/shared/PatientBanner.vue";
import Psychology from "@/components/patient/details/psychology/Psychology.vue";
import NutritionCard from "@/components/patient/details/nutrition/NutritionCard.vue";
import Nutrition from "@/components/patient/details/nutrition/Nutrition.vue";

const components = {
  FolderComponent,
  PatientBanner,
  Record,
  Historical,
  Psychology,
  PsychologyCard,
  Files,
  ABC,
  ABCNotes,
  FilesCard,
  Laboratories,
  CreateMedicNote,
  NutritionCard,
  Nutrition,
};

export default components;
