var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      !_vm.getViewMode &&
      (_vm.getUserType === 'medic' ||
        _vm.getUserType === 'superadmin' ||
        _vm.getUserType === 'nursing')
    )?_c('div',{staticClass:"d-flex justify-content-end mb-3 record__relative--sticky record__relative--sticky__bottom"},[(!_vm.editable)?_c('button',{staticClass:"btn btn-primary d-flex align-items-center m-3 px-3",on:{"click":function($event){_vm.editable = true}}},[_c('font-awesome-icon',{staticClass:"tableIcon me-4 text-white",attrs:{"icon":['fa', 'edit']}}),_vm._v(" Editar ")],1):_vm._e(),(!_vm.editable)?_c('button',{staticClass:"btn btn-primary d-flex align-items-center m-3 px-3",on:{"click":_vm.generatePDF}},[_c('font-awesome-icon',{staticClass:"tableIcon me-4 text-white",attrs:{"icon":['fa', 'file-pdf']}}),_vm._v(" Descargar ")],1):[_c('button',{staticClass:"btn btn-primary d-flex align-items-center m-3 px-3",on:{"click":function($event){_vm.editable = false}}},[_c('font-awesome-icon',{staticClass:"tableIcon me-4 text-white",attrs:{"icon":['fa', 'times-circle']}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"btn btn-primary d-flex align-items-center m-3 px-3",on:{"click":_vm.saveData}},[_c('font-awesome-icon',{staticClass:"tableIcon me-4 text-white",attrs:{"icon":['fa', 'save']}}),_vm._v(" Enviar ")],1)]],2):_vm._e(),_c('RecordPdf',{attrs:{"idPDF":_vm.idPatientPDF}}),_c('ObstetricsGynecology',{ref:"ObstetricsGynecology",attrs:{"editable":_vm.editable}}),_c('MenstrualHistory',{ref:"MenstrualHistory",attrs:{"editable":_vm.editable}}),_c('RecordHistory',{ref:"RecordHistory",attrs:{"editable":_vm.editable}}),(
      !_vm.getViewMode &&
      (_vm.getUserType === 'medic' ||
        _vm.getUserType === 'superadmin' ||
        _vm.getUserType === 'nursing') &&
      _vm.editable
    )?_c('div',{staticClass:"d-flex justify-content-end mb-3 record__relative--sticky record__relative--sticky__bottom"},[_c('button',{staticClass:"btn btn-primary d-flex align-items-center m-3 px-3",on:{"click":function($event){_vm.editable = false}}},[_c('font-awesome-icon',{staticClass:"tableIcon me-4 text-white",attrs:{"icon":['fa', 'times-circle']}}),_vm._v(" Cancelar ")],1),_c('button',{staticClass:"btn btn-primary d-flex align-items-center m-3 px-3",on:{"click":_vm.saveData}},[_c('font-awesome-icon',{staticClass:"tableIcon me-4 text-white",attrs:{"icon":['fa', 'save']}}),_vm._v(" Enviar ")],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }