<template>
  <div class="card card-citmer cardRadius mb-4 cardHeight internalBackground">
    <NutritionTable :patientNutritions="patientNutritions" />
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import NutritionTable from "./NutritionTable.vue";

export default {
  name: "Nutrition",
  components: { NutritionTable },
  mounted() {
    this.getPatientNutritions();
  },
  data() {
    return {
      patientNutritions: [],
    };
  },
  methods: {
    getPatientNutritions() {
      this.$api.patient
        .getPatientNutritions({ patient_id: this.$route.params.id })
        .then((res) => {
          this.patientNutritions = res.data.nutritions;
        });
    },
  },
};
</script>
