<template>
  <div class="card py-3 card-citmer cardRadius mb-3 internalBackground">
    <div class="px-4 mb-3">
      <div
        class="
          row
          justify-content-between
          align-items-center
          skyBlueBannerLight
          p-3
        ">
        <div class="col text-center">
          <span class="fw-bold">Solicitar Análisis</span>
        </div>
        <div class="col px-5 text-center">
          <button
            :disabled="canRequestLabs"
            v-b-modal.requestCard
            class="
              btn
              radiusButton
              py-2
              fw-bold
              form-control
              analisisBtn analisisBtn__active
            ">
            Andrología
          </button>
        </div>
        <div class="col px-5 text-center">
          <button
            class="btn radiusButton py-2 fw-bold form-control analisisBtn">
            FIV
          </button>
        </div>
        <div class="col px-5 text-center">
          <button
            class="btn radiusButton py-2 fw-bold form-control analisisBtn">
            General
          </button>
        </div>
        <div class="col"></div>
      </div>
    </div>
    <b-table
      :items="items"
      :fields="fields"
      emptyText="No hay registros"
      show-empty
      :table-class="'patientsHeadClass '"
      striped
      borderless>
      <template v-slot:cell(laboratory_type)="row">
        <span v-text="translateLaboratories(row.item.laboratory_type)"></span>
      </template>
      <template v-slot:cell(status)="row">
        <span v-text="translateLaboratories(row.item.status)"></span>
      </template>
      <template v-slot:cell(actions)="row">
        <template>
          <font-awesome-icon
            v-b-modal.resultsCard
            @click="chargeData(row.item)"
            class="mx-2 text-secondary pointer tableSizeIcon"
            :icon="['fa', 'edit']" />
        </template>
      </template>
      <!-- ACTIONS / -->
    </b-table>
    <b-modal
      ref="resultsCard"
      id="resultsCard"
      size="xl"
      centered
      :body-class="'p-0'"
      :title="'Solicitud'"
      :title-class="' fw-bold'"
      header-close-content=""
      :header-class="['titles']"
      hide-footer>
      <Results :laboratory="laboratory" />
    </b-modal>
    <b-modal
      ref="requestCard"
      id="requestCard"
      size="xl"
      centered
      :body-class="'p-0'"
      :title="'Solicitud'"
      :title-class="' fw-bold'"
      header-close-content=""
      :header-class="['titles']"
      hide-footer>
      <Request />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import { mapGetters } from "vuex";
import Results from "./Results.vue";
import Request from "./Request.vue";

export default {
  name: "LaboratoriesComponent",
  components: { Results, Request },
  data() {
    return {
      items: [],
      fields: [
        {
          class: "text-center ",
          key: "laboratory_type",
          label: "Servicio",
        },
        { class: "text-center ", key: "status", label: "Estatus" },
        {
          class: "text-center ",
          key: "actions",
          label: "",
        },
      ],
      laboratory: {},
    };
  },
  mounted() {
    this.$api.settings.getScheduleServices();
    this.getPatientLaboratories();
  },
  methods: {
    getPatientLaboratories() {
      this.$api.patient
        .getPatientLaboratories({ patient_id: this.$route.params.id })
        .then((res) => {
          this.items = res.data.laboratories;
        });
    },
    chargeData(item) {
      this.laboratory = item;
    },
    translateLaboratories(translate) {
      switch (translate) {
        case "freezing":
          return "Congelación";
        case "spermogram":
          return "Espermograma";
        case "pending":
          return "Pendiente";
        case "results":
          return "Resultados";
        default:
          return "";
      }
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
    canRequestLabs() {
      return (
        this.getUserType !== "nursing" &&
        this.getUserType !== "medic" &&
        this.getUserType !== "superadmin"
      );
    },
  },
};
</script>
