<template>
  <div class="py-3 px-4 mb-3">
    <div class="d-flex align-items-center">
      <div class="col-6">
        <div>
          <span class="text-primary fw-bold me-4">Nota Medica</span
          ><span class="fw-bold" v-text="patientNote.medic"></span>
        </div>
        <div>
          <span class="me-4">{{ formatDate }}</span
          ><span class=""
            >CEDULA:
            <span
              v-text="
                patientNote.medic_identification_card
                  ? patientNote.medic_identification_card
                  : 'N/A'
              "></span
          ></span>
        </div>
      </div>
      <div class="col-6">
        <div
          v-if="getUserType == 'superadmin'"
          class="d-flex justify-content-end align-items-center">
          <font-awesome-icon
            v-if="!editable"
            @click="editable = true"
            class="tableIcon me-4 text-primary"
            :icon="['fas', 'edit']" />
          <template v-else>
            <font-awesome-icon
              @click="editable = false"
              class="tableIcon me-4 text-primary"
              :icon="['fas', 'times-circle']" />
            <font-awesome-icon
              @click="putHistorical"
              class="tableIcon me-4 text-primary"
              :icon="['fas', 'save']" />
          </template>
        </div>
      </div>
    </div>
    <hr />
    <div class="p-3">
      <template v-if="!editable">
        <span class="text-center" v-text="patientNote.note"></span>
      </template>
      <template v-else>
        <textarea
          v-model="patientNote.note"
          class="form-control"
          rows="5"></textarea>
      </template>
    </div>
    <hr />
    <div
      v-for="(diagnostic, index) in patientNote.diagnostics"
      :key="index + diagnostic.name">
      <span class="me-4"
        >Diagnóstico:
        <span class="text-primary">(n{{ diagnostic.id }})</span></span
      >
      <span v-text="diagnostic.name"></span>
    </div>
    <hr />
    <div class="d-flex align-items-start">
      <div class="col-6">
        <div>
          <span class="text-primary me-4">Medicamentos</span>
        </div>
        <div class="">
          <ul class="simpleList">
            <li
              v-for="(medicine, index) in patientNote.medicines"
              :key="index + medicine.description"
              v-text="medicine.description"></li>
          </ul>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-end align-items-center">
          <span class="me-4"
            >Plan:
            <span
              class="text-primary"
              v-text="patientNote.plan ? patientNote.plan : 'Pendiente'"></span
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "MedicNote",
  props: {
    patientNote: { type: Object, required: true },
  },
  data() {
    return {
      editable: false,
    };
  },
  methods: {
    putHistorical() {
      const payload = {
        medicines: this.patientNote.medicines,
        plan_id: this.patientNote.plan_id,
        diagnostics: this.patientNote.diagnostics,
        note: this.patientNote.note,
      };
      this.$api.patient
        .putHistorical(this.patientNote.historical_id, payload)
        .then(() => {
          this.$parent.$parent.$parent.getHistoricals();
          this.$parent.$parent.$parent.$bvModal.hide("medicNote");
        });
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
    formatDate() {
      const unformatDate = new Date(this.patientNote.created_at);
      return this.$moment(unformatDate).format("DD/MM/YYYY");
    },
  },
};
</script>
