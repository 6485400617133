<template>
  <div class="record">
    <ul v-if="getHasPartner" class="nav nav-tabs mb-3">
      <li class="nav-item">
        <p
          class="nav-link pointer"
          @click="type = 'RecordPatient'"
          :class="{ active: type === 'RecordPatient' }">
          Paciente
        </p>
      </li>
      <li class="nav-item">
        <p
          class="nav-link pointer"
          @click="type = 'RecordPartner'"
          :class="{ active: type === 'RecordPartner' }">
          Pareja
        </p>
      </li>
    </ul>
    <div class="record__relative">
      <transition name="scale" mode="out-in">
        <KeepAlive>
          <component ref="componentBaseRecord" :is="type" />
        </KeepAlive>
      </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import { mapGetters } from "vuex";
import RecordPartner from "./RecordPartner.vue";
import RecordPatient from "./RecordPatient.vue";

export default {
  name: "Record",
  components: {
    RecordPartner,
    RecordPatient,
  },
  data() {
    return {
      editable: false,
      type: "RecordPatient",
    };
  },
  computed: {
    ...mapGetters(["getHasPartner"]),
  },
};
</script>
