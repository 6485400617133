<template>
  <!-- styles mods are inside _abc.scss -->
  <b-modal
    ref="liquidationABC"
    id="liquidationABC"
    content-class="p-4"
    size="md"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer>
    <div class="followUpModal">
      <h3 class="mb-3">Liquidar Plan</h3>
      <div class="mb-3">
        <span
          v-text="
            `El monto ingresado es menor que el adeudo, quedaria un adeudo de $${remain}. ¿Desea continuar de todas formas?`
          "></span>
      </div>
      <div class="row justify-content-between px-2">
        <button
          class="btn btn-secondary ms-1 col-5"
          @click="cancelModal"
          v-text="'Cancelar'" />
        <button
          class="btn btn-primary ms-1 col-5"
          v-text="'Guardar'"
          @click="onSubmit" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "FollowUpTasks",
  props: {
    remain: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "liquidationABC",
      ref: this.$refs.liquidationABC,
    });
  },
  methods: {
    cancelModal() {
      this.$store.getters.getModal("liquidationABC").hide();
    },
    onSubmit() {
      this.cancelModal();
      this.$parent.saveData();
    },
  },
};
</script>
