<template>
  <div class="px-5 py-3">
    <div class="fs-5 text-primary">
      <span v-text="patientNote.date"></span>
    </div>
    <div class="fs-5">
      <p></p>
    </div>
    <div>
      <span class="text-primary">Nota: </span>
      <span v-text="patientNote.note"></span>
    </div>
    <div>
      <span class="text-primary">Nombre del psicologo: </span>
      <span v-text="patientNote.psychologist_name"></span>
    </div>
    <div>
      <span class="text-primary">Servicio: </span>
      <span v-text="translateService(patientNote.service_type)"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PsychologyNote",
  props: {
    patientNote: {
      type: Object,
      required: true,
    },
  },
  methods: {
    translateService(service) {
      switch (service) {
        case "tracing":
          return "Seguimiento";
        default:
          return "Primera visita";
      }
    },
  },
};
</script>
