<template>
  <div class="px-5 py-3">
    <div class="mb-0">
      <span class="text-primary">Nota: </span>
      <span v-text="patientNote.note"></span>
    </div>
    <div class="my-3">
      <div class="row gx-5 justify-content-between mb-0">
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">Estatura: </span>
          <span v-text="patientNote.height"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">Peso: </span>
          <span v-text="patientNote.weight"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">IMC: </span>
          <span v-text="patientNote.bmi"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">% Grasa: </span>
          <span v-text="patientNote.fat"></span>
        </div>
      </div>
      <div class="row gx-5 justify-content-between mb-0">
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">% Musculo: </span>
          <span v-text="patientNote.muscle"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">Grasa Viseral: </span>
          <span v-text="patientNote.visceral_fat"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">Edad Metabolica: </span>
          <span v-text="patientNote.metabolic_age"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">% C. Cadera: </span>
          <span v-text="patientNote.hip"></span>
        </div>
      </div>
      <div class="row gx-5 justify-content-between mb-0">
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">C. Cintura: </span>
          <span v-text="patientNote.waist"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">C. Abdominal: </span>
          <span v-text="patientNote.abdominal"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">Presion Arterial: </span>
          <span v-text="patientNote.blood_pressure"></span>
        </div>
        <div class="d-flex justify-content-between col-3">
          <span class="text-primary">Frecuencia Cardiaca: </span>
          <span v-text="patientNote.heart_rate"></span>
        </div>
      </div>
    </div>
    <div class="mb-0">
      <span class="text-primary">Servicio: </span>
      <span v-text="translateService(patientNote.service_type)"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NutritionNote",
  props: {
    patientNote: {
      type: Object,
      required: true,
    },
  },
  methods: {
    translateService(service) {
      switch (service) {
        case "tracing":
          return "Seguimiento";
        default:
          return "Primera visita";
      }
    },
  },
};
</script>
