<template>
  <div class="mb-3">
    <!-- CARD -->
    <div class="card py-3 card-citmer cardRadius mb-3">
      <!-- HEADER CARD -->
      <div class="d-flex align-items-center">
        <div class="col-11 titles text-center ps-5 ms-2 mb-0">
          Historial Menstrual
        </div>
      </div>
      <hr />
      <!-- HEADER CARD END -->

      <!-- FORM -->
      <div class="p-5">
        <div class="d-flex align-items-center mb-3">
          <div class="col-4">
            <label>Anticonceptivo previo</label>
          </div>
          <div class="col-8">
            <input
              v-model="menstrualData.contraceptive"
              class="form-control text-center inputsGeneralClass w-100"
              :disabled="!editable" />
          </div>
        </div>

        <!-- HEADER TABLE -->
        <div class="row">
          <div class="col-2"></div>
          <div class="d-flex text-primary col-10">
            <span class="col-3 text-center">Menarquia</span>
            <span class="col-3 text-center">Menopausia</span>
            <span class="col-3 text-center">Dismenorrea</span>
            <span class="col-3 text-center"></span>
          </div>
        </div>
        <!-- HEADER TABLE END -->

        <!-- TABLE CONTENT -->
        <div class="row mb-4">
          <div class="d-flex flex-column col-2 align-items-start">
            <span></span>
            <span></span>
          </div>
          <div class="d-flex flex-column col-10">
            <div class="row gap-0">
              <div class="col-3 text-center">
                <input
                  v-model="menstrualData.menarche"
                  class="form-control text-center inputsGeneralClass w-100"
                  type="number"
                  :disabled="!editable" />
              </div>
              <div class="col-3 text-center">
                <select
                  v-model="menstrualData.menopause"
                  class="form-select text-center inputsGeneralClass w-100"
                  :disabled="!editable">
                  <option :value="true">Si</option>
                  <option :value="false">No</option>
                </select>
              </div>
              <div class="col-3 text-center">
                <input
                  v-model="menstrualData.dysmenorrhea"
                  class="form-control text-center inputsGeneralClass w-100"
                  type="text"
                  :disabled="!editable" />
              </div>
              <div class="col-3 text-center">
                <select
                  v-model="menstrualData.menstrual_type"
                  class="form-select text-center inputsGeneralClass w-100"
                  type="number"
                  :disabled="!editable">
                  <option value="normal">Normal</option>
                  <option value="irregular">Irregular</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <!-- TABLE CONTENT END -->
        <div class="mb-4">
          <textarea
            v-model="menstrualData.observations"
            class="form-control inputsGeneralClass w-100"
            rows="5"
            placeholder="Observaciones"
            :disabled="!editable"></textarea>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row justify-content-start col-4">
            <div class="col-lg-12 col-xl-6">
              <label>¿Tabaquismo?: </label>
            </div>
            <div class="d-flex col-lg-12 col-xl-6">
              <div class="form-check col-6">
                <label class="form-check-label" for="flexRadioDefault1"
                  >Si</label
                >
                <input
                  v-model="menstrualData.smoking"
                  class="form-check-input"
                  type="radio"
                  :value="true"
                  :disabled="!editable"
                  id="flexRadioDefault1" />
              </div>
              <div class="form-check col-6">
                <label class="form-check-label" for="flexRadioDefault1"
                  >No</label
                >
                <input
                  v-model="menstrualData.smoking"
                  class="form-check-input"
                  type="radio"
                  :value="false"
                  :disabled="!editable"
                  id="flexRadioDefault1" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center col-4 align-items-center">
            <div class="col-lg-12 col-xl-4">
              <label>Cantidad: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="menstrualData.amount"
                type="text"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
          <div class="row justify-content-end col-4 align-items-center">
            <div class="col-lg-12 col-xl-5">
              <label>Papanicolau: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="menstrualData.pap_smear"
                type="date"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row justify-content-start col-4">
            <div class="col-lg-12 col-xl-6">
              <label>¿Alcoholismo?: </label>
            </div>
            <div class="d-flex col-lg-12 col-xl-6">
              <div class="form-check col-6">
                <label class="form-check-label" for="flexRadioDefault1"
                  >Si</label
                >
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="menstrualData.alcoholism"
                  :value="true"
                  :disabled="!editable"
                  id="flexRadioDefault1" />
              </div>
              <div class="form-check col-6">
                <label class="form-check-label" for="flexRadioDefault1"
                  >No</label
                >
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="menstrualData.alcoholism"
                  :value="false"
                  :disabled="!editable"
                  id="flexRadioDefault1" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center col-4 align-items-center">
            <div class="col-lg-12 col-xl-4">
              <label>Frecuencia: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="menstrualData.frequency"
                type="text"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
          <div class="row justify-content-end col-4 align-items-center">
            <div class="col-lg-12 col-xl-5">
              <label>DX Papanicolau: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="menstrualData.dx_pap_smear"
                type="date"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row col-7 align-items-center">
            <div class="col-lg-12 col-xl-4">
              <label>Otros: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="menstrualData.others"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
          <div class="row justify-content-start col-1"></div>
          <div class="row justify-content-end col-4 align-items-center">
            <div class="col-lg-12 col-xl-5">
              <label>VPH: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <select
                v-model="menstrualData.hpv"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <!-- CARD END -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "MenstrualHistory",
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      gestaciones: "si",
      menstrualData: {
        contraceptive: "",
        menarche: 0,
        menopause: false,
        dysmenorrhea: 0,
        menstrual_type: "normal",
        observations: "",
        smoking: false,
        alcoholism: false,
        amount: 0,
        frequency: 0,
        pap_smear: new Date(),
        dx_pap_smear: new Date(),
        others: "",
        hpv: false,
      },
    };
  },
  mounted() {
    this.$api.patient.getRecordMenstrual(this.$route.params.id).then((res) => {
      this.menstrualData = res.data;
    });
  },
  methods: {
    putRecordMenstrual() {
      this.$api.patient
        .putRecordMenstrual(this.$route.params.id, this.menstrualData)
        .then(() => {
          this.editable = false;
        });
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
};
</script>
.
