<template>
  <div class="card py-3 card-citmer cardRadius mb-3">
    <!-- HEADER CARD -->
    <div class="d-flex align-items-center">
      <div class="col-11 mb-0 titles text-center ps-5 ms-2">Antecedentes</div>
    </div>
    <hr />
    <!-- HEADER CARD END -->

    <!-- FORM -->
    <div class="p-5">
      <div class="mb-3">
        <label>Antecedentes Familiares relevantes</label>
        <textarea
          v-model="recordData.relatives"
          class="form-control inputsGeneralClass w-100"
          :disabled="!editable"></textarea>
      </div>
      <div class="mb-3">
        <label>Antecedentes Personales no patológicos</label>
        <textarea
          v-model="recordData.non_pathological"
          class="form-control inputsGeneralClass w-100"
          :disabled="!editable"></textarea>
      </div>
      <div class="mb-3">
        <label>Antecedentes Personales patológicos y Cirugías</label>
        <textarea
          v-model="recordData.pathological"
          class="form-control inputsGeneralClass w-100"
          :disabled="!editable"></textarea>
      </div>
      <div class="mb-3">
        <label>Alergías</label>
        <textarea
          v-model="recordData.allergies"
          class="form-control inputsGeneralClass w-100"
          :disabled="!editable"></textarea>
      </div>
    </div>
    <!-- FORM END -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "RecordHistory",
  props: {
    editable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      recordData: {
        relatives: "",
        non_pathological: "",
        pathological: "",
        allergies: "",
      },
    };
  },
  mounted() {
    this.$api.patient.getRecord(this.$route.params.id).then((res) => {
      this.recordData = res.data;
    });
  },
  methods: {
    putRecord() {
      this.$api.patient
        .putRecord(this.$route.params.id, this.recordData)
        .then(() => {
          this.editable = false;
        });
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
};
</script>
.
