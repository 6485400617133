var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-citmer cardRadius mb-4 abcCardHeight internalBackground"},[_c('ABCBanner',{ref:"ABCBanner",attrs:{"bannerValues":_vm.abcInfo}}),_c('FollowUpStatus',{ref:"FollowUpStatus",attrs:{"showFollowUpFlag":_vm.showFollowUpFlag,"folloUpID":_vm.folloUpID}}),_c('FollowUpPending',{ref:"FollowUpPending",attrs:{"showFollowUpFlag":_vm.showFollowUpFlag,"folloUpID":_vm.folloUpID}}),_c('ul',{staticClass:"nav nav-pills nav-fill"},_vm._l((_vm.tabsLists),function(tab,index){return _c('li',{key:index + tab.key,staticClass:"nav-item",on:{"click":function($event){return _vm.changeTabs(tab)}}},[_c('a',{staticClass:"nav-link",class:{ active: _vm.selectedTab === tab.key }},[_vm._v(_vm._s(tab.title))])])}),0),(_vm.selectedTab === 'notes')?_c('b-table',{attrs:{"items":_vm.abcList,"fields":_vm.fieldsABC,"table-class":'patientsHeadClass',"striped":"","borderless":""},scopedSlots:_vm._u([{key:"cell(service_type)",fn:function(row){return [_c('span',{attrs:{"title":_vm.turnValue(row.item.service_type)},domProps:{"textContent":_vm._s(_vm.limitService(_vm.turnValue(row.item.service_type)))}})]}},{key:"cell(abc_id)",fn:function(row){return [_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.medicNoteABC",modifiers:{"medicNoteABC":true}}],staticClass:"btn text-secondary",domProps:{"textContent":_vm._s('Ver nota')},on:{"click":function($event){return _vm.getPatientABC(row.item.abc_id)}}})]}}],null,false,3114130467)}):_vm._e(),(_vm.selectedTab === 'pending')?_c('b-table',{attrs:{"items":_vm.followUpPending,"fields":_vm.fieldsFollowUpPending,"table-class":'patientsHeadClass',"emptyText":"No hay registros","show-empty":"","striped":"","borderless":""},scopedSlots:_vm._u([{key:"cell(flag)",fn:function(row){return [_c('font-awesome-icon',{class:{
          'text-danger': _vm.isExpired(row.item),
          'text-muted': !_vm.isExpired(row.item),
        },attrs:{"icon":['fas', 'flag']}})]}},{key:"cell(created_at)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.created_at ? _vm.$moment(row.item.created_at).format("DD/MM/YYYY HH:mm") : "")+" ")]}},{key:"cell(date_time)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.date_time ? _vm.$moment(row.item.date_time).format("DD/MM/YYYY HH:mm") : "")+" ")]}},{key:"cell(type_follow_up_task)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.followUpTypeTranslate[row.item.type_follow_up_task])+" ")]}},{key:"cell(action)",fn:function(row){return [_c('span',{staticClass:"me-2"},[_c('font-awesome-icon',{staticClass:"text-primary pointer",attrs:{"icon":['fas', 'pencil-alt']},on:{"click":function($event){return _vm.editStatus(row.item.id)}}})],1),_c('span',{staticClass:"ms-2"},[_c('font-awesome-icon',{staticClass:"text-primary pointer",attrs:{"icon":['fas', 'info-circle']},on:{"click":function($event){return _vm.showFollowUpPending(row.item.id)}}})],1)]}}],null,false,2389391762)}):_vm._e(),(
      _vm.selectedTab === 'completed' || _vm.selectedTab === 'completed_out_of_time'
    )?_c('b-table',{attrs:{"items":_vm.followUpCompleted,"fields":_vm.fieldsFollowUpCompleted,"table-class":'patientsHeadClass',"striped":"","borderless":""},scopedSlots:_vm._u([{key:"cell(flag)",fn:function(row){return [_c('font-awesome-icon',{class:{
          'text-danger': row.item.status === 'completed_out_of_time',
          'text-success': row.item.status === 'completed',
        },attrs:{"icon":['fas', 'flag']}})]}},{key:"cell(created_at)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.created_at ? _vm.$moment(row.item.created_at).format("DD/MM/YYYY HH:mm") : "")+" ")]}},{key:"cell(date_time)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.date_time ? _vm.$moment(row.item.date_time).format("DD/MM/YYYY HH:mm") : "")+" ")]}},{key:"cell(type_follow_up_task)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.followUpTypeTranslate[row.item.type_follow_up_task])+" ")]}},{key:"cell(action)",fn:function(row){return [_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":['fas', 'info-circle']},on:{"click":function($event){return _vm.showFollowUp(row.item.id)}}})]}}],null,false,2511699682)}):_vm._e(),_c('b-modal',{ref:"medicNoteABC",attrs:{"id":"medicNoteABC","size":"xl","centered":"","body-class":'p-3',"title":'Primera Asesoría Online',"title-class":'fw-bold p-3',"header-close-content":"","header-class":['fw-bold', 'text-primary', ''],"hide-footer":""}},[_c('ABCNote',{attrs:{"patientNote":_vm.abc}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }