<template>
  <div
    class="abcBanner"
    :class="{
      'bg-danger': isPaidLessThanHalfOwe,
      'bg-warning': !isPaidLessThanHalfOwe && !isCompleteOwe,
      'text-dark': !isPaidLessThanHalfOwe && !isCompleteOwe,
      'bg-secondary':
        isCompleteOwe &&
        (bannerValues.plan_name !== '' ||
          bannerValues.plan_name !== 'Pendiente'),
    }">
    <div class="row mb-3" v-if="bannerValues">
      <div class="col">
        <span>Plan</span> <strong>{{ bannerValues.plan_name }}</strong>
      </div>
    </div>
    <div class="row m-auto text-center">
      <div class="col">
        <p>
          <strong>{{ toCurrencyFormat(bannerValues.cost) }}</strong>
        </p>
        <p>Precio</p>
      </div>
      <div class="col">
        <p>
          <strong>{{ toCurrencyFormat(bannerValues.paid) }}</strong>
        </p>
        <p>Pagado</p>
      </div>
      <div class="col">
        <p>
          <strong>{{ toCurrencyFormat(bannerValues.owe) }}</strong>
        </p>
        <p>Adeudo</p>
      </div>
      <div class="col">
        <p>
          <strong>{{
            bannerValues.attempts ? bannerValues.attempts : 0
          }}</strong>
        </p>
        <p class="mb-0">Intentos</p>
        <p
          v-if="bannerValues.attempt_type != 'attempt'"
          v-text="translateAttemptType(bannerValues.attempt_type)"></p>
      </div>
      <div class="col">
        <p>
          <strong>{{ bannerValues.realized }}</strong>
        </p>
        <p>Realizados</p>
      </div>
      <div class="col">
        <p>
          <strong>{{ bannerValues.cycles }}</strong>
        </p>
        <p>M.Ciclos</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ABCBanner",
  props: {
    bannerValues: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isPaidLessThanHalfOwe() {
      const halfOwe = this.bannerValues.owe / 2;
      const { paid } = this.bannerValues;
      return paid < halfOwe;
    },
    isCompleteOwe() {
      const { owe } = this.bannerValues;
      return owe === 0;
    },
  },
  methods: {
    translateAttemptType(attemptType) {
      switch (attemptType) {
        case "puncture":
          return "Punción";
        case "sampling":
          return "Muestra";
        default:
          return "";
      }
    },
    toCurrencyFormat(data) {
      return data?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    limitPlan(bannerValue) {
      if (
        bannerValue.title === "Plan" &&
        JSON.stringify(bannerValue.value).length > 14
      ) {
        return `${JSON.stringify(bannerValue.value).slice(1, 14)}...`;
      }
      return bannerValue.value;
    },
  },
};
</script>
.
