<template>
  <div
    class="card card-citmer cardRadius mb-4 abcCardHeight internalBackground">
    <ABCBanner ref="ABCBanner" :bannerValues="abcInfo" />
    <FollowUpStatus
      ref="FollowUpStatus"
      :showFollowUpFlag="showFollowUpFlag"
      :folloUpID="folloUpID" />
    <FollowUpPending
      ref="FollowUpPending"
      :showFollowUpFlag="showFollowUpFlag"
      :folloUpID="folloUpID" />
    <ul class="nav nav-pills nav-fill">
      <li
        v-for="(tab, index) in tabsLists"
        :key="index + tab.key"
        class="nav-item"
        @click="changeTabs(tab)">
        <a class="nav-link" :class="{ active: selectedTab === tab.key }">{{
          tab.title
        }}</a>
      </li>
    </ul>
    <!-- NOTES LIST -->
    <b-table
      v-if="selectedTab === 'notes'"
      :items="abcList"
      :fields="fieldsABC"
      :table-class="'patientsHeadClass'"
      striped
      borderless>
      <template v-slot:cell(service_type)="row">
        <span
          v-text="limitService(turnValue(row.item.service_type))"
          :title="turnValue(row.item.service_type)"></span>
      </template>
      <template v-slot:cell(abc_id)="row">
        <button
          v-b-modal.medicNoteABC
          @click="getPatientABC(row.item.abc_id)"
          class="btn text-secondary"
          v-text="'Ver nota'"></button>
      </template>
    </b-table>
    <!-- NOTES LIST / -->
    <!-- PENDING LIST -->
    <b-table
      v-if="selectedTab === 'pending'"
      :items="followUpPending"
      :fields="fieldsFollowUpPending"
      :table-class="'patientsHeadClass'"
      emptyText="No hay registros"
      show-empty
      striped
      borderless>
      <template v-slot:cell(flag)="row">
        <font-awesome-icon
          :class="{
            'text-danger': isExpired(row.item),
            'text-muted': !isExpired(row.item),
          }"
          :icon="['fas', 'flag']" />
      </template>
      <template v-slot:cell(created_at)="row">
        {{
          row.item.created_at
            ? $moment(row.item.created_at).format("DD/MM/YYYY HH:mm")
            : ""
        }}
      </template>
      <template v-slot:cell(date_time)="row">
        {{
          row.item.date_time
            ? $moment(row.item.date_time).format("DD/MM/YYYY HH:mm")
            : ""
        }}
      </template>
      <template v-slot:cell(type_follow_up_task)="row">
        {{ followUpTypeTranslate[row.item.type_follow_up_task] }}
      </template>
      <template v-slot:cell(action)="row">
        <span class="me-2">
          <font-awesome-icon
            class="text-primary pointer"
            @click="editStatus(row.item.id)"
            :icon="['fas', 'pencil-alt']" />
        </span>
        <span class="ms-2">
          <font-awesome-icon
            @click="showFollowUpPending(row.item.id)"
            class="text-primary pointer"
            :icon="['fas', 'info-circle']" />
        </span>
      </template>
    </b-table>
    <!-- PENDING LIST /-->
    <!-- Completed LIST -->
    <b-table
      v-if="
        selectedTab === 'completed' || selectedTab === 'completed_out_of_time'
      "
      :items="followUpCompleted"
      :fields="fieldsFollowUpCompleted"
      :table-class="'patientsHeadClass'"
      striped
      borderless>
      <template v-slot:cell(flag)="row">
        <font-awesome-icon
          :class="{
            'text-danger': row.item.status === 'completed_out_of_time',
            'text-success': row.item.status === 'completed',
          }"
          :icon="['fas', 'flag']" />
      </template>
      <template v-slot:cell(created_at)="row">
        {{
          row.item.created_at
            ? $moment(row.item.created_at).format("DD/MM/YYYY HH:mm")
            : ""
        }}
      </template>
      <template v-slot:cell(date_time)="row">
        {{
          row.item.date_time
            ? $moment(row.item.date_time).format("DD/MM/YYYY HH:mm")
            : ""
        }}
      </template>
      <template v-slot:cell(type_follow_up_task)="row">
        {{ followUpTypeTranslate[row.item.type_follow_up_task] }}
      </template>
      <template v-slot:cell(action)="row">
        <font-awesome-icon
          @click="showFollowUp(row.item.id)"
          class="text-primary"
          :icon="['fas', 'info-circle']" />
      </template>
    </b-table>
    <!-- Completed LIST /-->
    <b-modal
      ref="medicNoteABC"
      id="medicNoteABC"
      size="xl"
      centered
      :body-class="'p-3'"
      :title="'Primera Asesoría Online'"
      :title-class="'fw-bold p-3'"
      header-close-content=""
      :header-class="['fw-bold', 'text-primary', '']"
      hide-footer>
      <ABCNote :patientNote="abc" />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import { mapMutations } from "vuex";
import { serviceTypes } from "@/constants/patient";
import ABCBanner from "./ABCBanner.vue";
import FollowUpStatus from "./FollowUpStatus.vue";
import FollowUpPending from "./FollowUpPending.vue";
import ABCNote from "./ABCNote.vue";

export default {
  name: "ABC",
  components: { ABCBanner, FollowUpStatus, FollowUpPending, ABCNote },
  data() {
    return {
      serviceTypes,
      abcInfo: {},
      folloUpID: 0,
      showFollowUpFlag: false,
      followUpList: [],
      abcList: [],
      abc: {
        date: "",
        note: "",
        plan_name: "",
        service_type: "",
      },
      followUpTypeTranslate: {
        call: "Llamada",
        whatsapp: "Whatsapp",
        virtual: "Virtual",
      },
      selectedTab: "notes",
      tabsLists: [
        {
          key: "notes",
          title: "Notas",
          getList: "getPatientABCs",
        },
        {
          key: "pending",
          title: "TS Pendientes",
          getList: "getFollowUps",
        },
        {
          key: "completed",
          title: "TS Completadas",
          getList: "getFollowUps",
        },
      ],
      fieldsABC: [
        {
          class: "text-center",
          key: "date",
          label: "Fecha",
        },
        { class: "text-center", key: "hour", label: "Hora" },
        { class: "text-center", key: "service_type", label: "Servicio" },
        {
          class: "text-center",
          key: "abc_id",
          label: "Nota ABC",
        },
      ],
      fieldsFollowUpPending: [
        { class: "text-center", key: "flag", label: "" },
        { class: "text-center", key: "date_time", label: "Fecha Programada" },
        { class: "text-center", key: "created_at", label: "Fecha Creación" },
        { class: "text-center", key: "title", label: "Título" },
        {
          class: "text-center",
          key: "type_follow_up_task",
          label: "Tipo",
        },
        { class: "text-center", key: "action", label: "Acción" },
      ],
      fieldsFollowUpCompleted: [
        { class: "text-center", key: "flag", label: "" },
        { class: "text-center", key: "date_time", label: "Fecha Programada" },
        { class: "text-center", key: "created_at", label: "Fecha Creación" },
        { class: "text-center", key: "title", label: "Título" },
        {
          class: "text-center",
          key: "type_follow_up_task",
          label: "Tipo",
        },
        { class: "text-center", key: "action", label: "Acción" },
      ],
    };
  },
  mounted() {
    this.getPatientABCs();
    this.getABCInfo();
  },
  computed: {
    followUpPending() {
      return this.followUpList.filter((follow) => follow.status === "pending");
    },
    followUpCompleted() {
      return this.followUpList.filter(
        (follow) =>
          follow.status === "completed" ||
          follow.status === "completed_out_of_time"
      );
    },
  },
  methods: {
    ...mapMutations(["setOwe", "setPlanName"]),
    turnValue(value) {
      if (value === "") return "";
      const service = this.serviceTypes.filter(
        (serviceType) => serviceType.value === value
      );
      return service[0].text;
    },
    getABCInfo() {
      this.$api.patient
        .getABCInfo({ patient_id: this.$route.params.id })
        .then((res) => {
          this.abcInfo = res.data;
          this.setOwe(this.abcInfo.owe);
          this.setPlanName(this.abcInfo.plan_name);
        });
    },
    showFollowUpPending(id) {
      this.folloUpID = id;
      this.showFollowUpFlag = true;
      this.$store.getters.getModal("followUpPendingModal").show();
    },
    showFollowUp(id) {
      this.folloUpID = id;
      this.showFollowUpFlag = true;
      this.$store.getters.getModal("followUpTaskStatusModal").show();
    },
    editStatus(id) {
      this.folloUpID = id;
      this.$store.getters.getModal("followUpTaskStatusModal").show();
    },
    isExpired(data) {
      const today = this.$moment();
      return today.isAfter(data.date_time);
    },
    changeTabs(tab) {
      this.selectedTab = tab.key;
      this[tab.getList]();
    },
    getPatientABCs() {
      this.$api.patient
        .getPatientsABC({ patient_id: this.$route.params.id })
        .then((res) => {
          this.abcList = res.data.abcs;
        });
    },
    getFollowUps() {
      this.$api.patient
        .getFollowUp({ patient_id: this.$route.params.id })
        .then((res) => {
          this.followUpList = res.data.follow_up_tasks;
        });
    },
    limitService(service) {
      if (JSON.stringify(service).length > 16) {
        return `${JSON.stringify(service).slice(1, 14)}...`;
      }
      return service;
    },
    getPatientABC(id) {
      this.$api.patient.getPatientABC(id).then((res) => {
        this.abc = res.data;
      });
    },
  },
};
</script>
