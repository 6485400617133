var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"abcBanner",class:{
    'bg-danger': _vm.isPaidLessThanHalfOwe,
    'bg-warning': !_vm.isPaidLessThanHalfOwe && !_vm.isCompleteOwe,
    'text-dark': !_vm.isPaidLessThanHalfOwe && !_vm.isCompleteOwe,
    'bg-secondary':
      _vm.isCompleteOwe &&
      (_vm.bannerValues.plan_name !== '' ||
        _vm.bannerValues.plan_name !== 'Pendiente'),
  }},[(_vm.bannerValues)?_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('span',[_vm._v("Plan")]),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.bannerValues.plan_name))])])]):_vm._e(),_c('div',{staticClass:"row m-auto text-center"},[_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.toCurrencyFormat(_vm.bannerValues.cost)))])]),_c('p',[_vm._v("Precio")])]),_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.toCurrencyFormat(_vm.bannerValues.paid)))])]),_c('p',[_vm._v("Pagado")])]),_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.toCurrencyFormat(_vm.bannerValues.owe)))])]),_c('p',[_vm._v("Adeudo")])]),_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.bannerValues.attempts ? _vm.bannerValues.attempts : 0))])]),_c('p',{staticClass:"mb-0"},[_vm._v("Intentos")]),(_vm.bannerValues.attempt_type != 'attempt')?_c('p',{domProps:{"textContent":_vm._s(_vm.translateAttemptType(_vm.bannerValues.attempt_type))}}):_vm._e()]),_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.bannerValues.realized))])]),_c('p',[_vm._v("Realizados")])]),_c('div',{staticClass:"col"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.bannerValues.cycles))])]),_c('p',[_vm._v("M.Ciclos")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }