var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"followUpTaskModal",attrs:{"id":"followUpTaskModal","content-class":"p-4","size":"md","no-close-on-backdrop":"","no-close-on-esc":"","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"followUpModal"},[_c('h3',{staticClass:"mb-3"},[_vm._v("Tarea de Seguimiento")]),_c('form',{staticClass:"followUpModal__form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"form-label mb-0",attrs:{"for":"title"}},[_c('span',{staticClass:"blueCitmer"},[_vm._v("*")]),_vm._v("Título")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUp.title),expression:"followUp.title"}],staticClass:"form-control inputsGeneralClassSecond",class:{
            'is-invalid':
              _vm.$v.followUp.title.$error || _vm.$v.followUp.title.$invalid,
          },attrs:{"type":"text"},domProps:{"value":(_vm.followUp.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.followUp, "title", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-2"},[_c('label',{attrs:{"for":"type_follow_up_task"}},[_c('span',{staticClass:"blueCitmer"},[_vm._v("*")]),_vm._v("Tipo de tarea de seguimiento ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUp.type_follow_up_task),expression:"followUp.type_follow_up_task"}],staticClass:"form-control inputsGeneralClassSecond",class:{
            'is-invalid':
              _vm.$v.followUp.type_follow_up_task.$error ||
              _vm.$v.followUp.type_follow_up_task.$invalid,
          },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.followUp, "type_follow_up_task", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"call"}},[_vm._v("Llamada")]),_c('option',{attrs:{"value":"whatsapp"}},[_vm._v("Whatsapp")]),_c('option',{attrs:{"value":"virtual"}},[_vm._v("Virtual")])])]),_c('div',{staticClass:"form-group mb-2",class:{
          errorDate:
            _vm.$v.followUp.date_time.$error || _vm.$v.followUp.date_time.$invalid,
        }},[_c('label',{attrs:{"for":"date_time"}},[_c('span',{staticClass:"blueCitmer"},[_vm._v("*")]),_vm._v("Fecha y hora programada")]),_c('date-picker',{staticClass:"d-block w-100",attrs:{"input-class":"form-control inputsGeneralClassSecond","format":"DD-MM-YYYY HH:mm","disabled-date":_vm.notBeforeToday,"type":"datetime"},model:{value:(_vm.followUp.date_time),callback:function ($$v) {_vm.$set(_vm.followUp, "date_time", $$v)},expression:"followUp.date_time"}})],1),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"comment"}},[_vm._v("Comentario")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.followUp.comment),expression:"followUp.comment"}],staticClass:"form-control inputsGeneralClassSecond",attrs:{"cols":"25","rows":"5"},domProps:{"value":(_vm.followUp.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.followUp, "comment", $event.target.value)}}})]),_c('div',{staticClass:"row justify-content-end px-2"},[_c('button',{staticClass:"btn btn-primary ms-1 col-6",attrs:{"type":"submit"},domProps:{"textContent":_vm._s('Guardar')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }