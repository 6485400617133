<template>
  <div
    v-if="!getCompleteModeBanner"
    class="card py-3 px-4 card__shadow cardRadius mb-3">
    <HideIcon />
    <div class="d-flex align-items-center">
      <div class="mb-0 titles">Agregar Documento</div>
    </div>
    <hr />
    <div class="d-flex flex-column mb-4">
      <span class="text-start fw-bold">Titulo</span>
      <input
        v-model="uploadData.file_title"
        class="form-control inputsGeneralClass w-100" />
    </div>
    <div
      v-if="uploadData.image == ''"
      @click="$refs.fileInput.click()"
      class="
        d-flex
        flex-column
        px-4
        cardRadius
        text-center
        borderFiles
        justify-content-center
        pointer
        mb-4
      ">
      <span>
        <font-awesome-icon
          class="uploadIcon text-primary"
          :icon="['fas', 'cloud-upload-alt']" />
      </span>
      <span class="">Cargar archivo</span>
    </div>
    <div
      v-else
      @click="removeImage"
      class="
        d-flex
        flex-column
        px-4
        cardRadius
        text-center
        borderFiles
        justify-content-center
        pointer
        mb-4
      ">
      <span>
        <font-awesome-icon
          class="uploadIcon text-primary"
          :icon="['fas', 'trash-alt']" />
      </span>
      <span class="" v-text="uploadData.old_file_title"></span>
    </div>
    <div>
      <button
        @click="postFile"
        class="btn btn-primary form-control cardRadius"
        :disabled="uploadData.image == '' || uploadData.file_title == ''">
        Subir
      </button>
    </div>
    <input
      class="d-none"
      type="file"
      ref="fileInput"
      eslint-disable-next-line
      @change="onFileChange"
      single />
  </div>
</template>

<script>
/* eslint-disable vue/valid-v-model */
/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import Form from "@/helpers/form";
import HideIcon from "@/components/patient/HideIcon.vue";

export default {
  name: "FilesCard",
  components: {
    HideIcon,
  },
  data() {
    return {
      image: "",
      uploadData: new Form({
        image: "",
        file_type: "",
        file_title: "",
        old_file_title: "",
      }),
    };
  },
  mounted() {
    this.updateBannerMode(false);
    this.updateHidenSecondaryComponent(false);
  },
  methods: {
    ...mapActions(["updateBannerMode", "updateHidenSecondaryComponent"]),
    postFile() {
      const formData = new FormData();
      formData.append("patient_id", parseInt(this.$route.params.id, 10));
      formData.append("name", this.uploadData.file_title);
      formData.append("file_type", this.uploadData.file_type);
      formData.append("file", this.uploadData.image);
      this.$api.patient.postFile(formData).then(() => {
        this.$parent.getFiles();
        this.uploadData.reset();
        this.removeImage();
      });
    },
    onFileChange(e) {
      const image = this.$refs.fileInput.files;
      [this.uploadData.image] = image;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const fileType = e.target.files[0].type.split("/")[0];
      this.uploadData.old_file_title = e.target.files[0].name;
      this.uploadData.file_type = fileType === "image" ? "image" : "file";
    },
    removeImage() {
      this.image = "";
      this.uploadData.image = "";
      this.$refs.fileInput.value = "";
    },
  },
  computed: {
    ...mapGetters(["getCompleteModeBanner"]),
  },
};
</script>
.
