<template>
  <div
    class="card py-3 card__shadow cardRadius mb-3"
    v-if="validPsychologyCard && !getCompleteModeBanner">
    <HideIcon />
    <div class="d-flex px-4 align-items-center">
      <div class="titles">Notas</div>
    </div>
    <hr />
    <div class="px-4 mb-5">
      <b-form-textarea
        class="form-control cardRadius inputsGeneralClass w-100"
        :state="!$v.notes.$error"
        v-model="notes"
        placeholder="Notas..."
        style="resize: none"
        rows="6"></b-form-textarea>
    </div>
    <div class="px-4 text-end">
      <button class="btn" @click="saveData" :disabled="!isFormDataValid">
        <font-awesome-icon
          class="psichoCardIcon text-primary"
          :icon="['fas', 'save']" />
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import HideIcon from "@/components/patient/HideIcon.vue";

export default {
  name: "PsychologyCard",
  components: {
    HideIcon,
  },
  data() {
    return {
      notes: "",
      currentAppointment: false,
      user_types: [],
      patient_psychology_last: null,
    };
  },
  validations: {
    notes: {
      required,
    },
  },
  mounted() {
    this.$v.$reset();
    this.getCurrentAppointment();
    this.getPatientPsychologyLast();
    if (!this.validPsychologyCard) {
      this.updateBannerMode(true);
      this.updateHidenSecondaryComponent(true);
    } else {
      this.updateBannerMode(false);
      this.updateHidenSecondaryComponent(false);
    }
  },
  methods: {
    ...mapActions(["updateBannerMode", "updateHidenSecondaryComponent"]),
    getCurrentAppointment() {
      this.$api.appointments
        .currentAppointment({ patient_id: this.$route.params.id })
        .then((res) => {
          this.currentAppointment = res.data.current_appointment;
          this.user_types = res.data.user_types;
        });
    },
    getPatientPsychologyLast() {
      const payload = {
        patient_id: this.$route.params.id,
      };
      this.$api.patient.getPatientPsychologyLast(payload).then((res) => {
        this.patient_psychology_last = res.data;
      });
    },
    saveData() {
      const payload = {
        patient_id: this.$route.params.id,
        note: this.notes,
        appointment_id: this.$route.params.appointment_id,
      };
      this.$api.patient.postPatientPsychology(payload).then(() => {
        this.$parent.$refs.componentMain.getPatientPsychologies();
        this.$parent.keySecondary += 1;
      });
    },
  },
  computed: {
    ...mapGetters(["getUserType", "getCompleteModeBanner"]),
    validPsychologyCard() {
      return (
        this.getUserType === "psychology" ||
        (this.currentAppointment &&
        this.user_types.includes(this.getUserType) &&
        this.patient_psychology_last
          ? this.patient_psychology_last.appointment_id != null
          : false)
      );
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error;
    },
  },
};
</script>
