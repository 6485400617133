<template>
  <div class="py-3 mb-3">
    <div class="row px-4 align-items-center justify-content-between my-3">
      <!-- <div class="col-6">
        <label>Auxiliar</label>
        <select
          v-model="assistant_id"
          class="form-control inputsGeneralClass w-100">
          <option
            v-for="(assistant, index) in assistants"
            :key="index"
            :value="assistant.id"
            v-text="assistant.name"></option>
        </select>
      </div>
      <div class="row col-6">
        <div class="row col-6 text-center ">
          <label class="" for="flexRadioDefault1">Congelación</label>
          <div>
            <input
              v-model="laboratory_type"
              class="form-check-input "
              type="radio"
              value="freezing" />
          </div>
        </div> 
        <div class="row col-6 text-center">
          <label class="" for="flexRadioDefault1">Espermograma</label>
          <div>
            <input
              v-model="laboratory_type"
              class="form-check-input"
              type="radio"
              value="spermogram" />
          </div>
        </div> 
      </div> -->
      <div class="col-12 mt-4 mb-3">
        <textarea
          v-model="observations"
          class="form-control text-secondary inputsGeneralClass w-100"
          placeholder="Observaciones"
          rows="5"></textarea>
      </div>
      <div class="d-flex justify-content-end">
        <div class="col-2 px-2 me-2">
          <button
            @click="$bvModal.hide('requestCard')"
            class="btn btn-secondary fw-bold form-control py-3 cardRadius">
            Cancelar
          </button>
        </div>
        <div class="col-2 px-2">
          <button
            @click="saveLaboratory"
            class="btn btn-primary fw-bold form-control py-3 cardRadius">
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "RequestCard",
  data() {
    return {
      laboratory_type: "spermogram",
      assistant_id: null,
      assistants: [],
      observations: "",
    };
  },
  created() {
    const payload = {
      clinic_id: this.getClinic,
    };
    this.$api.patient.getAssistants(payload).then((res) => {
      this.assistants = res.data.assistants;
      if (this.assistants.length > 0) {
        this.assistant_id = this.assistants[0].id;
      }
    });
  },
  methods: {
    saveLaboratory() {
      const payload = {
        laboratory_type: this.laboratory_type,
        observations: this.observations,
        patient_id: this.$route.params.id,
      };
      this.$api.patient.postLaboratory(payload).then(() => {
        this.$bvModal.hide("requestCard");
        this.$parent.$parent.$parent.getPatientLaboratories();
      });
    },
  },
  computed: {
    ...mapGetters(["getClinic"]),
  },
};
</script>
