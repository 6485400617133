<template>
  <div>
    <b-table
      :items="patientNutritions"
      :fields="psinutriFields"
      emptyText="No hay registros"
      show-empty
      :table-class="'patientsHeadClass '"
      striped
      borderless>
      <template v-slot:cell(service_type)="row">
        <span v-text="translateService(row.item.service_type)"></span>
      </template>
      <template v-slot:cell(nutrition_id)="row">
        <button
          v-b-modal.medicNoteNutrition
          @click="showNutrition(row.item.nutrition_id)"
          class="btn text-secondary"
          v-text="'Ver nota'"></button>
      </template>
    </b-table>
    <b-modal
      ref="medicNoteNutrition"
      id="medicNoteNutrition"
      size="xl"
      centered
      :body-class="'p-0'"
      :title="'Nota consulta nutricional'"
      :title-class="' fw-bold px-4 py-2'"
      header-close-content=""
      :header-class="['fw-bold', 'text-primary']"
      hide-footer>
      <NutritionNote :patientNote="nutrition" />
    </b-modal>
  </div>
</template>

<script>
import NutritionNote from "./NutritionNote.vue";

export default {
  name: "NutritionTable",
  props: {
    patientNutritions: {
      type: Array,
      required: true,
    },
  },
  components: { NutritionNote },
  data() {
    return {
      psinutriFields: [
        {
          class: "text-center",
          key: "date",
          label: "Fecha",
        },
        { class: "text-center", key: "service_type", label: "Servicio" },
        { class: "text-center", key: "nutrition_id", label: "Nota" },
      ],
      nutrition: {},
    };
  },
  methods: {
    translateService(service) {
      switch (service) {
        case "tracing":
          return "Seguimiento";
        default:
          return "Primera visita";
      }
    },
    showNutrition(id) {
      this.$api.patient.getPatientNutrition(id).then((res) => {
        this.nutrition = res.data;
      });
    },
  },
};
</script>
