<template>
  <div class="px-5 py-2">
    <div class="text-primary"><span v-text="patientNote.date"></span></div>
    <div>
      <p class="fs-3" v-text="patientNote.note"></p>
    </div>
    <div>
      <span class="text-primary">Plan: </span>
      <span v-text="patientNote.plan_name"></span>
    </div>
    <div>
      <span class="text-primary">Etapa de negocio: </span>
      <span
        v-text="
          patientNote.service_type != ''
            ? turnValue(patientNote.service_type)
            : ''
        "></span>
    </div>
  </div>
</template>

<script>
import { serviceTypes } from "@/constants/patient";

export default {
  name: "ABCNote",
  props: {
    patientNote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      serviceTypes,
    };
  },
  methods: {
    turnValue(value) {
      if (value === "") return "";
      const service = this.serviceTypes.filter(
        (serviceType) => serviceType.value === value
      );
      return service[0].text;
    },
  },
};
</script>
