<template>
  <div v-if="!getCompleteModeBanner" class="bannerIconEdit d-none d-lg-block">
    <font-awesome-icon
      class="mx-2 text-secondary tableIcon"
      @click="setCompleteModeBanner(true)"
      title="Editar detalle paciente"
      :icon="['fa', 'eye-slash']" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "HideIcon",
  methods: {
    ...mapMutations(["setCompleteModeBanner"]),
  },
  computed: {
    ...mapGetters(["getCompleteModeBanner"]),
  },
};
</script>
