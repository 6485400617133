<template>
  <div>
    <b-table
      :items="patientPsychologies"
      :fields="psyFields"
      :table-class="'patientsHeadClass'"
      emptyText="No hay registros"
      show-empty
      striped
      borderless>
      <template v-slot:cell(service_type)="row">
        <span v-text="translateService(row.item.service_type)"></span>
      </template>
      <template v-slot:cell(psychology_id)="row">
        <button
          v-b-modal.medicNotePsychology
          @click="showPsychology(row.item.psychology_id)"
          class="btn text-secondary"
          v-text="'Ver nota'"></button>
      </template>
    </b-table>
    <b-modal
      ref="medicNotePsychology"
      id="medicNotePsychology"
      size="xl"
      centered
      :body-class="'p-0'"
      :title="'Nota consulta psicologica'"
      :title-class="' fw-bold px-4 py-2'"
      header-close-content=""
      :header-class="['fw-bold', 'text-primary', '']"
      hide-footer>
      <PsychologyNote :patientNote="psychology" />
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import PsychologyNote from "./PsychologyNote.vue";

export default {
  name: "PsychologyTable",
  props: {
    patientPsychologies: {
      type: Array,
      required: true,
    },
  },
  components: { PsychologyNote },
  data() {
    return {
      psyFields: [
        {
          class: "text-center ",
          key: "date",
          label: "Fecha",
        },
        { class: "text-center ", key: "service_type", label: "Servicio" },
        { class: "text-center ", key: "psychology_id", label: "Nota" },
      ],
      psychology: {},
    };
  },
  methods: {
    translateService(service) {
      switch (service) {
        case "tracing":
          return "Seguimiento";
        default:
          return "Primera visita";
      }
    },
    showPsychology(id) {
      this.$api.patient.getPatientPsychology(id).then((res) => {
        this.psychology = res.data;
      });
    },
  },
};
</script>
