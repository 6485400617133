<template>
  <div class="card card-citmer cardRadius mb-3 mt-0 pt-0 internalBackground">
    <div
      class="
        d-none
        row
        justify-content-between
        align-items-center
        skyBlueBannerLight
      ">
      <div class="col px-5 text-center">
        <button
          disabled
          class="
            btn
            radiusButton
            py-2
            fw-bold
            form-control
            analisisBtn analisisBtn__active
          ">
          Iniciar tratamiento
        </button>
      </div>
      <div class="col px-5 text-center">
        <button
          disabled
          class="
            btn
            radiusButton
            py-2
            fw-bold
            form-control
            analisisBtn analisisBtn__active
          ">
          Procedimiento extra
        </button>
      </div>
      <div class="col-5"></div>
    </div>
    <CompleteHistory :patientCompleteHistorical="patientCompleteHistorical" />
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import CompleteHistory from "../CompleteHistory.vue";

export default {
  name: "HistoricalComponent",
  components: { CompleteHistory },
  data() {
    return {
      patientCompleteHistorical: [],
    };
  },
  mounted() {
    this.getCompleteHistoryDetail();
  },
  methods: {
    getCompleteHistoryDetail() {
      this.$api.patient
        .getCompleteHistoryDetail(this.$route.params.id)
        .then((res) => {
          this.patientCompleteHistorical = res.data.complete_history_detail;
        });
    },
  },
};
</script>
