<template>
  <!-- styles mods are inside _abc.scss -->
  <b-modal
    ref="followUpTaskStatusModal"
    id="followUpTaskStatusModal"
    content-class="p-4"
    :size="showFollowUpFlag ? 'lg' : 'md'"
    hide-header
    hide-footer
    @hide="cancelModal">
    <div class="followUpModal">
      <h3 v-if="!showFollowUpFlag" class="text-secondary mb-3">
        Tarea de Seguimiento
      </h3>
      <h3 v-else class="text-secondary mb-3">Detalles de seguimiento</h3>
      <form @submit.stop.prevent="onSubmit" class="followUpModal__form">
        <div v-if="showFollowUpFlag" class="form-group mb-2">
          <label for="title" class="labelColor form-label mb-0"> Titulo </label>
          <p class="labelValueColor" v-text="followUp.title"></p>
        </div>
        <div v-if="!showFollowUpFlag" class="form-group mb-2">
          <label for="title" class="labelColor form-label mb-0">
            <span class="blueCitmer">*</span>Nota
          </label>
          <textarea
            :class="{
              'is-invalid':
                $v.followUp.note.$error || $v.followUp.note.$invalid,
            }"
            :disabled="showFollowUpFlag"
            class="labelValueColor form-control inputsGeneralClassSecond"
            v-model="followUp.note"
            name=""
            id=""
            rows="6"></textarea>
        </div>
        <div v-if="showFollowUpFlag" class="d-flex">
          <div class="col-4 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Fecha de creación
            </label>
            <p class="labelValueColor" v-text="followUp.created_at"></p>
          </div>
          <div class="col-4 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Fecha programada
            </label>
            <p class="labelValueColor" v-text="followUp.date_time"></p>
          </div>
          <div class="col-4 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Fecha de registro
            </label>
            <p
              class="labelValueColor"
              :class="
                followUp.status === 'completed_out_of_time' ? 'text-danger' : ''
              "
              v-text="followUp.updated_at"></p>
          </div>
        </div>
        <div v-if="showFollowUpFlag" class="d-flex">
          <div class="col-12 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Comentario ABC</label
            >
            <p
              class="labelValueColor"
              v-text="
                followUp.comment ? followUp.comment : 'Sin comentarios'
              "></p>
          </div>
        </div>
        <div v-if="!showFollowUpFlag" class="form-group mb-2 mt-3">
          <div
            class="btn-group"
            role="group"
            aria-label="Basic radio toggle button group">
            <input
              type="radio"
              v-model="followUp.answered"
              :disabled="showFollowUpFlag"
              class="btn-check"
              name="btnradio"
              value="true"
              id="btnradio1"
              autocomplete="off"
              checked />
            <label class="btn btn-outline-primary" for="btnradio1"
              >Respondio</label
            >
            <input
              type="radio"
              v-model="followUp.answered"
              :disabled="showFollowUpFlag"
              class="btn-check"
              name="btnradio"
              value="false"
              id="btnradio2"
              autocomplete="off" />
            <label class="btn btn-outline-primary" for="btnradio2"
              >No Respondio</label
            >
          </div>
        </div>
        <div v-else class="d-flex">
          <div class="col-6 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Medio de seguimiento
            </label>
            <p
              class="labelValueColor"
              v-text="followUpTypeTranslate[followUp.type_follow_up_task]"></p>
          </div>
          <div class="col-6 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0">
              Respondio
            </label>
            <p
              class="labelValueColor"
              v-text="followUp.answered ? 'Si' : 'No'"></p>
          </div>
        </div>
        <div v-if="showFollowUpFlag" class="d-flex">
          <div class="col-12 form-group mb-2">
            <label for="title" class="labelColor form-label mb-0"> Nota </label>
            <p
              class="labelValueColor"
              v-text="followUp.note ? followUp.note : 'Sin notas'"></p>
          </div>
        </div>
        <div class="d-flex pt-2">
          <template v-if="!showFollowUpFlag">
            <div class="col-6">
              <button
                class="btn btn-secondary me-1 w-100"
                @click="cancelModal()"
                v-text="'Cancelar'"
                type="button" />
            </div>
            <div class="col-6">
              <button
                class="btn btn-primary ms-1 w-100"
                v-text="'Guardar'"
                type="submit" />
            </div>
          </template>
          <div v-else class="col-12">
            <button
              class="btn btn-secondary me-1 w-100"
              @click="cancelModal()"
              v-text="'Aceptar'"
              type="button" />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import Form from "@/helpers/form";

export default {
  name: "FollowUpTasks",
  props: {
    folloUpID: {
      type: Number,
      required: true,
    },
    showFollowUpFlag: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    showFollowUpFlag(newValue) {
      if (newValue) {
        this.$api.patient.showFollowUp(this.folloUpID).then((res) => {
          this.followUp.update(res.data);
        });
      }
    },
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "followUpTaskStatusModal",
      ref: this.$refs.followUpTaskStatusModal,
    });
  },
  data() {
    return {
      followUp: new Form({
        note: "",
        answered: false,
        color: "",
        comment: "",
        created_at: null,
        date_time: null,
        title: "",
        type_follow_up_task: "",
        updated_at: null,
        status: "",
      }),
      followUpTypeTranslate: {
        call: "Llamada",
        whatsapp: "Whatsapp",
        virtual: "Virtual",
      },
    };
  },
  validations: {
    followUp: {
      note: {
        required,
      },
      answered: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["getClinic"]),
  },
  methods: {
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error && !this.$v.$invalid;
    },
    cancelModal() {
      this.$parent.showFollowUpFlag = false;
      this.followUp.reset();
      this.$store.getters.getModal("followUpTaskStatusModal").hide();
    },
    onSubmit() {
      if (!this.isFormDataValid()) return;
      const payload = this.followUp.data();
      payload.patient_id = this.$route.params.id;
      payload.clinic_id = this.getClinic;
      this.$api.patient.postFollowUpStatus(this.folloUpID, payload).then(() => {
        this.cancelModal();
        this.$parent.getFollowUps();
      });
    },
  },
};
</script>
