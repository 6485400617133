<template>
  <div class="card card-citmer cardRadius mb-4 cardHeight internalBackground">
    <PsychologyTable :patientPsychologies="patientPsychologies" />
  </div>
</template>

<script>
import PsychologyTable from "./PsychologyTable.vue";

export default {
  name: "Psychology",
  components: { PsychologyTable },
  mounted() {
    this.getPatientPsychologies();
  },
  data() {
    return {
      patientPsychologies: [],
    };
  },
  methods: {
    getPatientPsychologies() {
      this.$api.patient
        .getPatientPsychologies({ patient_id: this.$route.params.id })
        .then((res) => {
          this.patientPsychologies = res.data.psychologies;
        });
    },
  },
};
</script>
