<template lang="html">
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      filename="laboratorio"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="820px"
      @beforeDownload="beforeDownload($event)"
      ref="recordPDF">
      <div class="d-flex justify-content-center py-3 mb-3" slot="pdf-content">
        <div class="col-11 text-black">
          <div class="d-flex">
            <div class="col-8">
              <div class="row align-items-center justify-content-start">
                <div class="col-4">
                  <span class="fw-bold">NEC:</span>
                </div>
                <div class="col-8">
                  <span v-text="patientData.nec"></span>
                </div>
              </div>
              <div class="row align-items-center justify-content-start">
                <div class="col-4">
                  <span class="fw-bold">Nombre del paciente:</span>
                </div>
                <div class="col-8">
                  <span v-text="patientData.patient_name"></span>
                </div>
              </div>
              <div class="row align-items-center justify-content-start">
                <div class="col-4">
                  <span class="fw-bold">Edad:</span>
                </div>
                <div class="col-8">
                  <span v-text="patientData.patient_age"></span>
                </div>
              </div>
              <template v-if="getHasPartner">
                <div class="row align-items-center justify-content-start">
                  <div class="col-4">
                    <span class="fw-bold">Nombre de la pareja:</span>
                  </div>
                  <div class="col-8">
                    <span v-text="patientData.partner_name"></span>
                  </div>
                </div>
                <div class="row align-items-center justify-content-start">
                  <div class="col-4">
                    <span class="fw-bold">Edad:</span>
                  </div>
                  <div class="col-8">
                    <span v-text="patientData.partner_age"></span>
                  </div>
                </div>
              </template>
              <div class="row align-items-center justify-content-start">
                <div class="col-4">
                  <span class="fw-bold">Medico:</span>
                </div>
                <div class="col-8">
                  <span v-text="patientData.medic_name"></span>
                </div>
              </div>
              <div class="row align-items-center justify-content-start">
                <div class="col-4">
                  <span class="fw-bold">Primera visita:</span>
                </div>
                <div class="col-8">
                  <span v-text="patientData.first_appointment"></span>
                </div>
              </div>
            </div>
            <div class="col-4 text-end">
              <img :src="require('@/assets/images/citmerlogo.jpg')" alt="" />
            </div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <h3 class="mb-0 pb-0">Antecedentes</h3>
          </div>
          <hr class="m-0 p-0" />
          <div class="row align-items-center justify-content-start mb-4 mt-2">
            <div class="row g-0 col-2"></div>
            <div class="row g-0 col-10">
              <div class="col-3">
                <span>Partos</span>
              </div>
              <div class="col-3">
                <span>Cesareas</span>
              </div>
              <div class="col-3">
                <span>Abortos</span>
              </div>
              <div class="col-3">
                <span>Ectópicos</span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-2">
              <span class="fw-bold">A Termino:</span>
            </div>
            <div class="row g-0 col-10">
              <div class="col-3">
                <span
                  class="ms-3"
                  v-text="gynecologyData.term_childbirth"></span>
              </div>
              <div class="col-3">
                <span
                  class="ms-4 ps-1"
                  v-text="gynecologyData.term_caesarean"></span>
              </div>
              <div class="col-3">
                <span class="ms-4" v-text="gynecologyData.term_abortion"></span>
              </div>
              <div class="col-3">
                <span
                  class="ms-4 ps-2"
                  v-text="gynecologyData.term_ectopic"></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start mb-3">
            <div class="row g-0 col-2">
              <span class="fw-bold">A Pre-Termino:</span>
            </div>
            <div class="row g-0 col-10">
              <div class="col-3">
                <span
                  class="ms-3"
                  v-text="gynecologyData.pre_childbirth"></span>
              </div>
              <div class="col-3">
                <span
                  class="ms-4 ps-1"
                  v-text="gynecologyData.pre_caesarean"></span>
              </div>
              <div class="col-3"></div>
              <div class="col-3"></div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start mb-2">
            <div class="row g-0 col-4">
              <span class="fw-bold">¿Gestaciones de la misma pareja?:</span>
            </div>
            <div class="row g-0 col-1">
              <span
                class=""
                v-text="gynecologyData.same_partner ? 'Si' : 'No'"></span>
            </div>
          </div>
          <div class="row mb-2">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3"
                  >Observaciones gineco-obstetricas:
                </span>
                <span
                  v-text="
                    gynecologyData.observations !== ''
                      ? gynecologyData.observations
                      : 'Sin observaciones'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3">Anticonceptivo previo: </span>
                <span
                  v-text="
                    menstrualData.contraceptive !== ''
                      ? menstrualData.contraceptive
                      : 'Sin anticonceptivo previo'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-2"></div>
            <div class="row g-0 col-10">
              <div class="col-3">
                <span>Menarquia</span>
              </div>
              <div class="col-3">
                <span>Menopausia</span>
              </div>
              <div class="col-3">
                <span>Dismenorrea</span>
              </div>
              <div class="col-3">
                <span>Periodo</span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start mb-4">
            <div class="row g-0 col-2"></div>
            <div class="row g-0 col-10">
              <div class="col-3">
                <span v-text="menstrualData.menarche"></span>
              </div>
              <div class="col-3">
                <span v-text="menstrualData.menopause ? 'Si' : 'No'"></span>
              </div>
              <div class="col-3">
                <span v-text="menstrualData.dysmenorrhea"></span>
              </div>
              <div class="col-3">
                <span
                  v-text="
                    menstrualData.menstrual_type === 'normal'
                      ? 'Normal'
                      : 'Irregular'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3"
                  >Observaciones sobre la menstruación:
                </span>
                <span
                  v-text="
                    menstrualData.observations !== ''
                      ? menstrualData.observations
                      : 'Sin observaciones'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">¿Tabaquismo?:</span>
              </div>
              <div class="col-5">
                <span v-text="menstrualData.smoking ? 'Si' : 'No'"></span>
              </div>
            </div>
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">Cantidad:</span>
              </div>
              <div class="col-5">
                <span v-text="menstrualData.amount"></span>
              </div>
            </div>
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">Papanicolau:</span>
              </div>
              <div class="col-5">
                <span
                  v-text="
                    menstrualData.pap_smear ? menstrualData.pap_smear : 'No'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">¿Alcoholismo?:</span>
              </div>
              <div class="col-5">
                <span v-text="menstrualData.alcoholism ? 'Si' : 'No'"></span>
              </div>
            </div>
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">Frecuencia:</span>
              </div>
              <div class="col-5">
                <span v-text="menstrualData.frequency"></span>
              </div>
            </div>
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">DX Papanicolau:</span>
              </div>
              <div class="col-5">
                <span
                  v-text="
                    menstrualData.dx_pap_smear
                      ? menstrualData.dx_pap_smear
                      : 'No'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start mb-4">
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">Otros:</span>
              </div>
              <div class="col-5">
                <span
                  v-text="
                    menstrualData.others !== '' ? menstrualData.others : 'No'
                  "></span>
              </div>
            </div>
            <div class="row g-0 col-4">
              <div class="col-5">
                <span class="fw-bold">VPH:</span>
              </div>
              <div class="col-5">
                <span v-text="menstrualData.hpv ? 'Si' : 'No'"></span>
              </div>
            </div>
            <div class="row g-0 col-4"></div>
          </div>
          <div class="row mb-2">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3"
                  >Antecedentes Familiares relevantes:
                </span>
                <span
                  v-text="
                    recordData.relatives !== ''
                      ? recordData.relatives
                      : 'Sin observaciones'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3"
                  >Antecedentes Personales no patológicos:
                </span>
                <span
                  v-text="
                    recordData.non_pathological !== ''
                      ? recordData.non_pathological
                      : 'Sin observaciones'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3"
                  >Antecedentes Personales patológicos y Cirugías:
                </span>
                <span
                  v-text="
                    recordData.pathological !== ''
                      ? recordData.pathological
                      : 'Sin observaciones'
                  "></span>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="row g-0 col-12">
              <div>
                <span class="fw-bold me-3">Alergías: </span>
                <span
                  v-text="
                    recordData.allergies !== ''
                      ? recordData.allergies
                      : 'Sin observaciones'
                  "></span>
              </div>
            </div>
          </div>
          <template v-if="getHasPartner">
            <div class="d-flex justify-content-center mt-3">
              <h3 class="mb-0 pb-0">Antecedentes de pareja</h3>
            </div>
            <hr class="m-0 p-0" />
            <div class="row my-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3"
                    >Antecedentes Familiares relevantes:
                  </span>
                  <span
                    v-text="
                      recordPartnerData.relatives !== ''
                        ? recordPartnerData.relatives
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-start mb-2">
              <div class="row g-0 col-3">
                <div class="col-5">
                  <span class="fw-bold">Hermanos:</span>
                </div>
                <div class="col-5">
                  <span v-text="recordPartnerData.siblings"></span>
                </div>
              </div>
              <div class="row g-0 col-4">
                <div class="col-5">
                  <span class="fw-bold">Hijos previos:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="
                      recordPartnerData.previous_children ? 'Si' : 'No'
                    "></span>
                </div>
              </div>
              <div class="row g-0 col-5">
                <div class="col-7">
                  <span class="fw-bold">Gestiones con pareja actual:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="
                      recordPartnerData.gestations_with_current_partner
                        ? 'Si'
                        : 'No'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-start mb-2">
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Hermanos con hijos:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="recordPartnerData.siblings_with_children"></span>
                </div>
              </div>
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Abortos previos:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="
                      recordPartnerData.previous_abortions ? 'Si' : 'No'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3">Enfermedades: </span>
                  <span
                    v-text="
                      recordPartnerData.diseases !== ''
                        ? recordPartnerData.diseases
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-start mb-2">
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Diabetes:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="
                      translateDiabetes(recordPartnerData.diabetes)
                    "></span>
                </div>
              </div>
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Hipertension:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="
                      translateHypertension(recordPartnerData.hypertension)
                    "></span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3">Cirugía: </span>
                  <span
                    v-text="
                      recordPartnerData.surgerie !== ''
                        ? recordPartnerData.surgerie
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3">Alergías: </span>
                  <span
                    v-text="
                      recordPartnerData.allergies !== ''
                        ? recordPartnerData.allergies
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3">Farmacos: </span>
                  <span
                    v-text="
                      recordPartnerData.drugs !== ''
                        ? recordPartnerData.drugs
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3">Farmacos para estirilidad: </span>
                  <span
                    v-text="
                      recordPartnerData.drugs_for_infertility !== ''
                        ? recordPartnerData.drugs_for_infertility
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-start mb-2">
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Tabaquismo:</span>
                </div>
                <div class="col-5">
                  <span v-text="recordPartnerData.smoking ? 'Si' : 'No'"></span>
                </div>
              </div>
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Cantidad:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="
                      translateSmocking(recordPartnerData.smoking_quantity)
                    "></span>
                </div>
              </div>
            </div>
            <div class="row align-items-center justify-content-start mb-2">
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Alcoholismo:</span>
                </div>
                <div class="col-5">
                  <span
                    v-text="recordPartnerData.alcoholism ? 'Si' : 'No'"></span>
                </div>
              </div>
              <div class="row g-0 col-6">
                <div class="col-5">
                  <span class="fw-bold">Frecuencia:</span>
                </div>
                <div class="col-5">
                  <span v-text="recordPartnerData.alcoholism_frequency"></span>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="row g-0 col-12">
                <div>
                  <span class="fw-bold me-3">Otros padecimientos: </span>
                  <span
                    v-text="
                      recordPartnerData.others !== ''
                        ? recordPartnerData.others
                        : 'Sin observaciones'
                    "></span>
                </div>
              </div>
            </div>
          </template>
          <!-- <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center
              mt-5
              pt-5
            ">
            <div class="border-bottom border-dark text-white">
              _________________________________________
            </div>
            <div>{{ "" }}</div>
          </div> -->
        </div>
      </div>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapGetters } from "vuex";

export default {
  name: "RecordPDF",
  data() {
    return {
      gynecologyData: {
        observations: "",
        pre_caesarean: 0,
        pre_childbirth: 0,
        same_partner: false,
        term_abortion: 0,
        term_caesarean: 0,
        term_childbirth: 0,
        term_ectopic: 0,
      },
      menstrualData: {
        alcoholism: false,
        amount: 0,
        contraceptive: "",
        dx_pap_smear: "",
        dysmenorrhea: 0,
        frequency: 0,
        hpv: false,
        menarche: 0,
        menopause: false,
        menstrual_type: "",
        observations: "",
        others: "",
        pap_smear: null,
        smoking: false,
      },
      recordData: {
        allergies: "",
        non_pathological: "",
        pathological: "",
        relatives: "",
      },
      recordPartnerData: {
        alcoholism: false,
        alcoholism_frequency: "",
        allergies: "",
        diabetes: 1,
        diseases: "",
        drugs: "",
        drugs_for_infertility: "",
        gestations_with_current_partner: false,
        hypertension: 1,
        others: "",
        previous_abortions: false,
        previous_children: false,
        relatives: "",
        siblings: 0,
        siblings_with_children: 0,
        smoking: false,
        smoking_quantity: 1,
        surgerie: "",
      },
      patientData: {
        abc_name: null,
        cacit_name: "",
        contact_medium: null,
        first_appointment: "",
        has_partner: true,
        medic_name: "",
        nec: 2,
        partner_age: 0,
        partner_date_birth: "",
        partner_dial_code: 0,
        partner_email: "",
        partner_name: "",
        partner_tel: 0,
        patient_age: 0,
        patient_date_birth: "",
        patient_dial_code: 0,
        patient_email: "",
        patient_name: "",
        patient_tel: 0,
      },
    };
  },
  props: {
    idPDF: {
      type: Number,
      required: false,
    },
  },
  components: {
    VueHtml2pdf,
  },
  watch: {
    idPDF() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  updated() {
    if (this.idPDF) {
      this.$refs.recordPDF.generatePdf();
    }
  },
  methods: {
    getRecordGynecology() {
      this.$api.patient
        .getRecordGynecology(this.$route.params.id)
        .then((res) => {
          this.gynecologyData = res.data;
        });
    },
    getRecordMenstrual() {
      this.$api.patient
        .getRecordMenstrual(this.$route.params.id)
        .then((res) => {
          this.menstrualData = res.data;
        });
    },
    getRecord() {
      this.$api.patient.getRecord(this.$route.params.id).then((res) => {
        this.recordData = res.data;
      });
    },
    getRecordPartner() {
      this.$api.patient.getRecordPartner(this.$route.params.id).then((res) => {
        this.recordPartnerData = res.data;
      });
    },
    getPatient() {
      this.$api.patient.getPatient(this.$route.params.id).then((res) => {
        this.patientData = res.data;
      });
    },
    getData() {
      this.getRecordGynecology();
      this.getRecordMenstrual();
      this.getRecord();
      if (this.getHasPartner) {
        this.getRecordPartner();
      }
      this.getPatient();
    },
    translateDiabetes(translate) {
      const diabetes = {
        no: "No",
        controlled: "Controlada",
        uncontrolled: "Descontrolada",
        long_evolution: "De larga evolución",
        with_complications: "Con complicaciónes",
        insulin_resistance: "Resistencia a insulina",
        default: "",
      };
      return diabetes[translate] || diabetes.default;
    },
    translateHypertension(translate) {
      const hypertension = {
        no: "No",
        controlled: "Controlada",
        uncontrolled: "Descontrolada",
        default: "",
      };
      return hypertension[translate] || hypertension.default;
    },
    translateSmocking(translate) {
      const smoking = {
        social: "Social",
        from_1_to_3_daily: "1 a 3 diarios",
        from_4_to_10_daily: "4 a 10 diarios",
        from_10_to_19_daily: "10 a 19 diarios",
        more_than_20: "mas de 20",
        default: "",
      };
      return smoking[translate] || smoking.default;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(1);
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getUserProfile", "getHasPartner"]),
  },
};
</script>
