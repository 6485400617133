<template>
  <div
    class="card card__shadow cardRadius mb-3"
    v-if="
      (isABCUserType || getUserType == 'superadmin') && !getCompleteModeBanner
    ">
    <HideIcon />
    <div class="d-flex py-3 px-4 align-items-center">
      <div class="mb-0 titles">Notas</div>
    </div>
    <hr />
    <div class="py-2 px-4 mb-2">
      <textarea
        :class="{
          'is-invalid': $v.noteData.note.$error || $v.noteData.note.$invalid,
        }"
        class="form-control cardRadius inputsGeneralClass w-100"
        v-model="noteData.note"
        name=""
        id=""
        rows="6"></textarea>
    </div>
    <div class="d-flex py-3 px-4 align-items-center">
      <div class="mb-0 titles">Etapa de Negocio</div>
    </div>
    <hr />
    <div class="py-3 px-4 mb-4">
      <select
        v-model="noteData.service_type"
        :class="{
          'is-invalid':
            $v.noteData.service_type.$error ||
            $v.noteData.service_type.$invalid,
        }"
        class="form-select inputsGeneralClass w-100">
        <option value="" disabled>Selecciona etapa de negocio</option>
        <option
          v-for="(option, index) in serviceTypes"
          :key="index"
          :value="option.value"
          v-text="option.text"></option>
      </select>
    </div>
    <div
      v-if="noteData.service_type === 'return'"
      class="d-flex py-3 px-4 align-items-center">
      <div class="col-9">
        <b-form-input
          ref="inputRef"
          v-model="noteData.payment"
          :state="!$v.noteData.payment.$error && !$v.noteData.payment.$invalid"
          placeholder="Monto"
          class="inputsGeneralClass w-100 form-control"
          @keypress="isNumber($event)"
          type="text" />
      </div>
      <div class="col-3 text-end">
        <button @click="actionSave" class="btn">
          <font-awesome-icon
            class="notesIcon text-primary"
            :icon="['fas', 'save']" />
        </button>
      </div>
    </div>
    <!-- VENTA CERRADA -->
    <template v-if="noteData.service_type == 'closed_sale'">
      <div class="py-2 px-4 mb-2">
        <select
          v-model="noteData.sellCloseType"
          :class="{
            'is-invalid':
              $v.noteData.sellCloseType.$error ||
              $v.noteData.sellCloseType.$invalid,
          }"
          class="form-select inputsGeneralClass w-100">
          <option value="" disabled>Tipo de Venta Cerrada</option>
          <option value="advance">Anticipo Cierre</option>
          <option value="close">Cierre Liquida plan</option>
          <option value="pay_plan">Abono a plan</option>
          <option value="pay_off_plan">Líquida plan</option>
        </select>
      </div>
      <div class="d-flex py-3 px-4 align-items-center">
        <div class="col-9">
          <b-form-input
            ref="inputRef"
            v-model="noteData.payment"
            :state="
              !$v.noteData.payment.$error && !$v.noteData.payment.$invalid
            "
            placeholder="Monto"
            class="inputsGeneralClass w-100 form-control"
            type="text" />
        </div>
        <div class="col-3 text-end">
          <button @click="actionSave" class="btn">
            <font-awesome-icon
              class="notesIcon text-primary"
              :icon="['fas', 'save']" />
          </button>
        </div>
      </div>
    </template>
    <!-- VENTA CERRADA /-->
    <!-- CAMBIO DE PLAN -->
    <template v-if="noteData.service_type == 'change_plan'">
      <div class="d-flex py-3 px-4 align-items-center">
        <div class="titles">Plan</div>
      </div>
      <hr />
      <div class="row py-3 px-4 mb-3">
        <div class="d-flex align-items-center">
          <input v-model="isPromotionPriceActive" type="checkbox" /><span
            class="ms-2"
            >Precio promoción</span
          >
        </div>
        <div class="mt-4" v-if="isPromotionPriceActive">
          <label for="promo" class="form-label">Monto</label>
          <b-form-input
            :state="
              !$v.noteData.promotion_price.$error &&
              !$v.noteData.promotion_price.$invalid
            "
            v-model.lazy="noteData.promotion_price"
            class="form-control cardRadius inputsGeneralClass w-100"
            id="promo"
            ref="inputRef"
            type="text" />
        </div>
      </div>
      <div class="row py-2 px-4 mb-2">
        <span>Propuesta Médica</span>
        <span class="text-primary" v-text="plan_name"></span>
      </div>
      <div class="row py-2 px-4 mb-2">
        <span>Elegido por el paciente</span>
        <div class="d-flex align-items-center px-2">
          <div class="col-9">
            <select
              v-model="noteData.plan_id"
              :class="{
                'is-invalid':
                  $v.noteData.plan_id.$error || $v.noteData.plan_id.$invalid,
              }"
              class="form-select inputsGeneralClass w-100">
              <option
                v-for="(plan, index) in plans"
                :key="index"
                :value="plan.id"
                v-text="plan.name"></option>
            </select>
          </div>
          <div class="col-3 text-end">
            <button @click="actionSave" class="btn">
              <font-awesome-icon
                class="notesIcon text-primary"
                :icon="['fas', 'save']" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <!-- CAMBIO DE PLAN /-->
    <template
      v-if="
        noteData.service_type != 'closed_sale' &&
        noteData.service_type != 'change_plan' &&
        noteData.service_type != 'return' &&
        noteData.service_type !== ''
      ">
      <div class="d-flex py-3 px-4 align-items-center justify-content-end">
        <div class="col-3 text-end">
          <button @click="actionSave" class="btn">
            <font-awesome-icon
              class="notesIcon text-primary"
              :icon="['fas', 'save']" />
          </button>
        </div>
      </div>
    </template>
    <FollowUpTasks />
    <LiquidationABC :remain="remain" />
  </div>
</template>

<script>
// @ is an alias to /src
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";
import { serviceTypes } from "@/constants/patient";
import { useCurrencyInput } from "vue-currency-input";
import Form from "@/helpers/form";
import HideIcon from "@/components/patient/HideIcon.vue";
import LiquidationABC from "./LiquidationABC.vue";
import FollowUpTasks from "./FollowUpTasks.vue";

export default {
  name: "ABCNotes",
  components: {
    FollowUpTasks,
    LiquidationABC,
    HideIcon,
  },
  setup() {
    const { inputRef } = useCurrencyInput({
      currency: "USD",
      locale: "en",
      currencyDisplay: "narrowSymbol",
      precision: 2,
      valueRange: { min: 1, max: 9999999999 },
    });

    return { inputRef };
  },
  data() {
    return {
      serviceTypes,
      noteData: new Form({
        note: null,
        service_type: "",
        plan_id: null,
        sellCloseType: "",
        payment: null,
        promotion_price: null,
      }),
      isPromotionPriceActive: false,
      plan_name: "",
      amount: 0,
      plans: [],
      number: {
        decimal: ".",
        separator: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      remain: 0,
    };
  },
  validations() {
    return {
      noteData: {
        note: {
          required,
        },
        service_type: {
          required,
        },
        sellCloseType: {
          required: requiredIf(function payment() {
            return this.noteData.service_type === "closed_sale";
          }),
        },
        payment: {
          required: requiredIf(function payment() {
            return (
              this.noteData.service_type === "closed_sale" ||
              this.noteData.service_type === "return"
            );
          }),
        },
        plan_id: {
          required: requiredIf(function payment() {
            return this.noteData.service_type === "change_plan";
          }),
        },
        promotion_price: {
          required: requiredIf(function payment() {
            return (
              this.isPromotionPriceActive &&
              this.noteData.service_type === "change_plan"
            );
          }),
          minLength: minLength(2),
        },
      },
    };
  },
  mounted() {
    const fullMode =
      this.getUserType === "abc" || this.getUserType === "superadmin";
    if (!fullMode) {
      this.updateBannerMode(true);
      this.updateHidenSecondaryComponent(true);
    } else {
      this.updateBannerMode(false);
      this.updateHidenSecondaryComponent(false);
    }
  },
  created() {
    this.$api.patient.getPlans().then((res) => {
      this.plans = res.data.plans;
      this.noteData.plan_id = this.plans[0].id;
    });
    this.plan_name = this.getPlanName;
  },
  methods: {
    ...mapActions([
      "updateAlerts",
      "updateBannerMode",
      "updateHidenSecondaryComponent",
    ]),
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode !== 8 && charCode !== 0 && charCode < 48) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      const isValid = !this.$v.$error && !this.$v.$invalid;
      if (!isValid) {
        this.updateAlerts({
          status: "warning",
          content: "Debe llenar los campos.",
        });
      }
      return isValid;
    },
    actionSave() {
      const payload = this.noteData.data();
      if (!this.isFormDataValid()) return;
      if (this.isFollowUpNeeded) {
        this.$store.getters.getModal("followUpTaskModal").show();
        return;
      }
      if (
        payload.service_type === "closed_sale" &&
        payload.sellCloseType === "close" &&
        this.getOwe > parseInt(payload.payment, 10)
      ) {
        this.remain = this.getOwe - parseInt(payload.payment, 10);
        this.$store.getters.getModal("liquidationABC").show();
        return;
      }
      this.saveData();
    },
    generatePayload(hasFollowUp, idFollowUp) {
      const payload = this.noteData.data();
      payload.patient_id = parseInt(this.$route.params.id, 10);
      if (hasFollowUp) {
        payload.follow_up_task_id = idFollowUp;
      }
      if (
        !this.isPromotionPriceActive ||
        payload.service_type !== "change_plan"
      ) {
        delete payload.promotion_price;
      } else {
        payload.promotion_price = payload.promotion_price.replace("$", "");
        payload.promotion_price = payload.promotion_price.replace(",", "");
      }
      if (payload.service_type !== "change_plan") {
        delete payload.plan_id;
      }
      if (payload.service_type === "return") {
        payload.return = parseInt(payload.payment, 10);
      }
      if (payload.service_type !== "closed_sale") {
        delete payload.payment;
      }
      if (payload.service_type === "closed_sale") {
        payload.close_sale_type = payload.sellCloseType;
        payload.payment = payload.payment.replace("$", "");
        payload.payment = payload.payment.replace(",", "");
      }
      payload.appointment_id = this.$route.params.appointment_id;
      delete payload.sellCloseType;
      return payload;
    },
    saveData(hasFollowUp = false, idFollowUp = false) {
      const payload = this.generatePayload(hasFollowUp, idFollowUp);
      this.$api.patient.postPatientABC(payload).then(() => {
        this.$parent.$refs.componentMain.getPatientABCs();
        this.$parent.$refs.componentMain.getFollowUps();
        this.$parent.$refs.componentMain.getABCInfo();
        this.$parent.keySecondary += 1;
        this.noteData.reset();
      });
    },
  },
  computed: {
    ...mapGetters([
      "getUserType",
      "isABCUserType",
      "getOwe",
      "getPlanName",
      "getCompleteModeBanner",
    ]),
    isFollowUpNeeded() {
      if (this.noteData.service_type === "") return false;
      let dataOfSelectedNeedFollowUp = this.serviceTypes.find(
        (service) => service.value === this.noteData.service_type
      ).followUp;
      if (
        this.noteData.service_type === "closed_sale" &&
        this.noteData.sellCloseType === "close"
      ) {
        dataOfSelectedNeedFollowUp = false;
      }
      return dataOfSelectedNeedFollowUp;
    },
  },
};
</script>
.
