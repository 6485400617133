<template>
  <div>
    <div
      v-if="
        !getViewMode &&
        (getUserType === 'medic' ||
          getUserType === 'superadmin' ||
          getUserType === 'nursing')
      "
      class="
        d-flex
        justify-content-end
        mb-3
        record__relative--sticky record__relative--sticky__bottom
      ">
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        v-if="!editable"
        @click="editable = true">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'edit']" />
        Editar
      </button>
      <template v-else>
        <button
          class="btn btn-primary d-flex align-items-center m-3 px-3"
          @click="cancelData">
          <font-awesome-icon
            class="tableIcon me-4 text-white"
            :icon="['fa', 'times-circle']" />
          Cancelar
        </button>
        <button
          class="btn btn-primary d-flex align-items-center m-3 px-3"
          @click="putRecordPartner">
          <font-awesome-icon
            class="tableIcon me-4 text-white"
            :icon="['fa', 'save']" />
          Enviar
        </button>
      </template>
    </div>
    <div class="card py-3 card-citmer cardRadius mb-3">
      <!-- HEADER CARD -->
      <div class="d-flex align-items-center">
        <h4 class="titles col-11 text-center ps-5 ms-2 mb-0">
          Antecedentes Pareja
        </h4>
      </div>
      <hr />
      <!-- HEADER CARD END -->

      <!-- FORM -->
      <div class="p-5">
        <div class="mb-3">
          <label>Antecedentes Familiares relevantes</label>
          <textarea
            v-model="recordPartnerData.relatives"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row justify-content-center col-3 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Hermanos: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="recordPartnerData.siblings"
                type="number"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
          <div class="row justify-content-end col-4 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Hijos previos: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <select
                v-model="recordPartnerData.previous_children"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-end col-5 align-items-center">
            <div class="col-lg-12 col-xl-7">
              <label>Gestiones con pareja actual: </label>
            </div>
            <div class="col-lg-12 col-xl-5">
              <select
                v-model="recordPartnerData.gestations_with_current_partner"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row justify-content-center col-5 align-items-center">
            <div class="col-lg-12 col-xl-7">
              <label>Hermanos con hijos: </label>
            </div>
            <div class="col-lg-12 col-xl-5">
              <input
                v-model="recordPartnerData.siblings_with_children"
                type="number"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
          <div class="row justify-content-end col-5 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Abortos previos: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <select
                v-model="recordPartnerData.previous_abortions"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div class="row justify-content-end col-2 align-items-center"></div>
        </div>
        <div class="mb-3">
          <label>Enfermedades</label>
          <textarea
            v-model="recordPartnerData.diseases"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
        <div class="row align-items-center justify-content-start mb-4">
          <div class="row justify-content-center col-6 align-items-center">
            <div class="col-lg-12 col-xl-3">
              <label>Diabetes: </label>
            </div>
            <div class="col-lg-12 col-xl-9">
              <select
                v-model="recordPartnerData.diabetes"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option
                  v-for="(label, index) in diabetesLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`diabetes-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
          <div class="row justify-content-end col-5 align-items-center">
            <div class="col-lg-12 col-xl-4">
              <label>Hipertension: </label>
            </div>
            <div class="col-lg-12 col-xl-8">
              <select
                v-model="recordPartnerData.hypertension"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option
                  v-for="(label, index) in hypertensionLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`hypertension-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
          <div class="row justify-content-end col-4 align-items-center"></div>
        </div>
        <div class="mb-3">
          <label>Cirugía</label>
          <textarea
            v-model="recordPartnerData.surgerie"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
        <div class="mb-3">
          <label>Alergías</label>
          <textarea
            v-model="recordPartnerData.allergies"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
        <div class="mb-3">
          <label>Farmacos</label>
          <textarea
            v-model="recordPartnerData.drugs"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
        <div class="mb-3">
          <label>Farmacos para estirilidad</label>
          <textarea
            v-model="recordPartnerData.drugs_for_infertility"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row justify-content-center col-4 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Tabaquismo: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <select
                @change="changeSmoking"
                v-model="recordPartnerData.smoking"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div
            v-if="recordPartnerData.smoking"
            class="row justify-content-end col-4 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Cantidad: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <select
                v-model="recordPartnerData.smoking_quantity"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option
                  v-for="(label, index) in smokingQuantityLabels"
                  :value="label.value"
                  v-text="label.label"
                  :key="`smoking_quantity-${label.label}-${index}`"></option>
              </select>
            </div>
          </div>
          <div
            v-else
            class="row justify-content-end col-4 align-items-center"></div>
          <div class="row justify-content-end col-4 align-items-center"></div>
        </div>
        <div class="row align-items-center justify-content-between mb-4">
          <div class="row justify-content-center col-4 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Alcoholismo: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <select
                @change="changeAlcoholism"
                v-model="recordPartnerData.alcoholism"
                class="form-select text-center inputsGeneralClass w-100"
                :disabled="!editable">
                <option :value="true">Si</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
          <div
            v-if="recordPartnerData.alcoholism"
            class="row justify-content-end col-4 align-items-center">
            <div class="col-lg-12 col-xl-6">
              <label>Frecuencia: </label>
            </div>
            <div class="col-lg-12 col-xl-6">
              <input
                v-model="recordPartnerData.alcoholism_frequency"
                class="form-control text-center inputsGeneralClass w-100"
                :disabled="!editable" />
            </div>
          </div>
          <div
            v-else
            class="row justify-content-end col-4 align-items-center"></div>
          <div class="row col-4 align-items-center"></div>
        </div>
        <div class="mb-3">
          <label>Otros</label>
          <textarea
            v-model="recordPartnerData.others"
            class="form-control inputsGeneralClass w-100"
            :disabled="!editable"></textarea>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div
      v-if="
        !getViewMode &&
        (getUserType === 'medic' ||
          getUserType === 'superadmin' ||
          getUserType === 'nursing') &&
        editable
      "
      class="
        d-flex
        justify-content-end
        mb-3
        record__relative--sticky record__relative--sticky__bottom
      ">
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        @click="cancelData">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'times-circle']" />
        Cancelar
      </button>
      <button
        class="btn btn-primary d-flex align-items-center m-3 px-3"
        @click="putRecordPartner">
        <font-awesome-icon
          class="tableIcon me-4 text-white"
          :icon="['fa', 'save']" />
        Enviar
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import {
  smokingQuantityLabels,
  diabetesLabels,
  hypertensionLabels,
} from "@/constants/patient";

export default {
  name: "RecordPartner",
  data() {
    return {
      recordPartnerData: {
        relatives: "",
        siblings: 0,
        previous_children: false,
        gestations_with_current_partner: false,
        siblings_with_children: 0,
        previous_abortions: false,
        diseases: "",
        diabetes: 1,
        hypertension: 1,
        surgerie: "",
        allergies: "",
        drugs: "",
        drugs_for_infertility: "",
        others: "",
        smoking: false,
        smoking_quantity: 1,
        alcoholism: false,
        alcoholism_frequency: "",
      },
      smokingQuantityLabels,
      diabetesLabels,
      hypertensionLabels,
      editable: false,
    };
  },
  mounted() {
    this.getRecordPartner();
  },
  methods: {
    getRecordPartner() {
      this.$api.patient.getRecordPartner(this.$route.params.id).then((res) => {
        this.recordPartnerData = res.data;
      });
    },
    putRecordPartner() {
      this.$api.patient
        .putRecordPartner(this.$route.params.id, this.recordPartnerData)
        .then(() => {
          this.editable = false;
        });
    },
    changeAlcoholism() {
      if (!this.recordPartnerData.alcoholism) {
        this.recordPartnerData.alcoholism_frequency = "";
      }
    },
    changeSmoking() {
      if (!this.recordPartnerData.smoking) {
        this.recordPartnerData.smoking_quantity = 1;
      }
    },
    cancelData() {
      this.getRecordPartner();
      this.editable = false;
    },
  },
  computed: { ...mapGetters(["getUserType", "getViewMode"]) },
};
</script>
.
