<template>
  <div>
    <b-table
      :items="patientCompleteHistorical"
      :fields="completeHistoricalFields"
      :table-class="'patientsHeadClass'"
      emptyText="No hay registros"
      show-empty
      striped
      borderless>
      <template v-slot:cell(date)="row">
        <span>{{ $moment(row.item.date).format("DD/MM/YYYY") }}</span>
      </template>
      <template v-slot:cell(service_type)="row">
        <span
          v-text="limitService(turnValue(row.item.service_type))"
          :title="turnValue(row.item.service_type)"></span>
      </template>
      <template v-slot:cell(history_type)="row">
        <span v-text="translateHistory(row.item.history_type)"></span>
      </template>

      <template v-slot:cell(actions)="row">
        <button
          :disabled="getUserType === 'cash_register'"
          @click="showHistorical(row.item)"
          class="btn text-secondary"
          v-text="'Ver nota'"></button>
      </template>
    </b-table>
    <b-modal
      ref="patientNote"
      id="patientNote"
      size="xl"
      centered
      :body-class="'p-3'"
      :title="'Primera Asesoría Online'"
      :title-class="'fw-bold p-3'"
      header-close-content=""
      :header-class="['fw-bold', 'text-primary', '']"
      hide-footer>
      <component
        :ref="selectedComponent"
        :is="selectedComponent"
        :selectedComponent="selectedComponent"
        :patientNote="patientNote" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { serviceTypes } from "@/constants/patient";
import MedicNote from "@/components/patient/details/shared/MedicNote.vue";
import PsychologyNote from "./psychology/PsychologyNote.vue";
import ABCNote from "./abc/ABCNote.vue";
import NutritionNote from "./nutrition/NutritionNote.vue";

export default {
  name: "PsychologyTable",
  props: {
    patientCompleteHistorical: {
      type: Array,
      required: true,
    },
  },
  components: { PsychologyNote, ABCNote, NutritionNote, MedicNote },
  data() {
    return {
      serviceTypes,
      selectedComponent: "MedicNote",
      patientNote: {},
      completeHistoricalFields: [
        {
          class: "text-center ",
          key: "date",
          label: "Fecha",
        },
        { class: "text-center ", key: "hour", label: "Hora" },
        { class: "text-center ", key: "service_type", label: "Servicio" },
        { class: "text-center ", key: "history_type", label: "Historico" },
        { class: "text-center", key: "actions", label: "" },
      ],
    };
  },
  methods: {
    showHistorical(data) {
      switch (data.history_type) {
        case "abc":
          this.getPatientABC(data.id);
          break;
        case "historical":
          this.getPatientHistorical(data.id);
          break;
        case "nutrition":
          this.getPatientNutrition(data.id);
          break;
        case "psychology":
          this.getPatientPsychology(data.id);
          break;
        default:
          break;
      }
    },
    translateHistory(translate) {
      const history = {
        abc: "ABC",
        historical: "Historial",
        nutrition: "Nutrición",
        psychology: "Psicología",
        default: "",
      };
      return history[translate] || history.default;
    },
    getPatientPsychology(id) {
      this.selectedComponent = "PsychologyNote";
      this.$api.patient.getPatientPsychology(id).then((res) => {
        this.patientNote = res.data;
        this.$refs.patientNote.show();
      });
    },
    getPatientABC(id) {
      this.selectedComponent = "ABCNote";
      this.$api.patient.getPatientABC(id).then((res) => {
        this.patientNote = res.data;
        this.$refs.patientNote.show();
      });
    },
    getPatientNutrition(id) {
      this.selectedComponent = "NutritionNote";
      this.$api.patient.getPatientNutrition(id).then((res) => {
        this.patientNote = res.data;
        this.$refs.patientNote.show();
      });
    },
    getPatientHistorical(id) {
      this.selectedComponent = "MedicNote";
      this.$api.patient.getHistorical(id).then((res) => {
        this.patientNote = res.data;
        this.$refs.patientNote.show();
      });
    },
    limitService(service) {
      if (JSON.stringify(service).length > 20) {
        return `${JSON.stringify(service).slice(1, 18)}...`;
      }
      return service;
    },
    turnValue(value) {
      if (value === "") return "";
      const service = this.serviceTypes.filter(
        (serviceType) => serviceType.value === value
      );
      if (service.length > 0) {
        return service[0].text;
      }
      return value;
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
  },
};
</script>
